import { PodcastForm } from '@app/components/forms/podcast-form'
import { PodcastPreview } from '@app/components/previews/podcast-preview'
import { FormLayout, InputField, SelectField, TextareaField, VideoField } from '@app/lib/form'
import { ImageField } from '@app/lib/plugins/image/image-field'
import { BlockEditorField } from '@app/lib/plugins/rich-text/editor'
import { SeoForm } from '@app/lib/plugins/seo/'
import { SlugField } from '@app/lib/plugins/url'
import { Component, useField } from '@contember/interface'
import { useCallback, useEffect, useRef, useState } from 'react'
import { DirectS3FileUploader } from '../direct-s3-upload'

export interface PodcastPostFormProps {
	over?: 'posts' | 'podcastPost' | 'podcastPostContent' | 'podcastPostSeo' | 'podcastPostUrl' | (string & {})
}

const directS3Uploader = new DirectS3FileUploader({
	s3Config: {
		accessKeyId: import.meta.env.VITE_AWS_ACCESS_KEY_ID,
		secretAccessKey: import.meta.env.VITE_AWS_SECRET_ACCESS_KEY,
		region: import.meta.env.VITE_AWS_REGION,
		bucket: import.meta.env.VITE_AWS_BUCKET,
	},
})

export const PodcastPostForm = Component(({ over }: PodcastPostFormProps) => (
	<FormLayout>
		<InputField field="title" label="Title" required />
		<SlugField label="Url" field="url.url" derivedFrom="title" />
		{over !== 'posts' && (
			<SelectField field="podcast" label="Podcast" createNewForm={<PodcastForm over="podcast" />}>
				<PodcastPreview />
			</SelectField>
		)}
		<div className="flex gap-4 w-fit">
			<div className="space-y-4">
				<ImageField label="Image" baseField="image" />
			</div>
			<VideoField
				uploader={directS3Uploader}
				baseField="video"
				urlField="url"
				durationField="duration"
				widthField="width"
				heightField="height"
				fileNameField="meta.fileName"
				fileSizeField="meta.fileSize"
				fileTypeField="meta.fileType"
				lastModifiedField="meta.lastModified"
				label="Video file"
			/>
		</div>
		<TextareaField field="subtitle" label="Short description" />
		<BlockEditorField field="content.data" referencesField="content.references" label="Content" />
		<SeoForm field="seo" />
	</FormLayout>
))
