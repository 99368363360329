import { FormLayout } from '@app/lib/form'
import { ImageField } from '@app/lib/plugins/image/image-field'
import { DefaultRepeater, RepeaterItemActions, RepeaterRemoveItemButton } from '@app/lib/repeater'
import { Component, HasOne } from '@contember/interface'
import { HeaderLinkForm } from './header-link-form'

export const HeaderForm = Component(() => (
	<FormLayout>
		<ImageField label="Logo" baseField="image" />

		<HasOne field="topLinkList">
			<DefaultRepeater field="links" title="Top header links" sortableBy="displayOrder">
				<HeaderLinkForm noIcon />
				<RepeaterItemActions>
					<RepeaterRemoveItemButton />
				</RepeaterItemActions>
			</DefaultRepeater>
		</HasOne>

		<HasOne field="linkList">
			<DefaultRepeater field="links" title="Header links" sortableBy="displayOrder">
				<HeaderLinkForm />
				<RepeaterItemActions>
					<RepeaterRemoveItemButton />
				</RepeaterItemActions>
			</DefaultRepeater>
		</HasOne>
	</FormLayout>
))
