import { Binding, PersistButton } from '@app/lib/binding'
import { SelectField } from '@app/lib/form'
import { Slots } from '@app/lib/layout'
import { DefaultRepeater, RepeaterItemActions, RepeaterRemoveItemButton } from '@app/lib/repeater'
import { EntitySubTree, Field, If } from '@contember/interface'

export default () => {
	return (
		<Binding>
			<Slots.Title>Homepage hero top 3 posts</Slots.Title>
			<Slots.Actions>
				<PersistButton />
			</Slots.Actions>
			<DefaultRepeater field="ArticleList" title="Homepage posts">
				<DefaultRepeater field="articles" title="Posts" sortableBy="displayOrder">
					<SelectField field="article" label="Post" initialSorting={{ publishedAt: 'desc' }}>
						<If condition={`[podcast.id = ${null}]`}>
							<div className="flex text-left gap-4">
								<div className="flex-none text-xs h-fit flex gap-1">
									<If condition="[status.articleStatus = published]">
										<div className="px-2 py-1 rounded font-semibold bg-lime-200 text-lime-700">
											<Field field="status.articleStatus" />
										</div>
									</If>
									<If condition="[status.articleStatus = draft]">
										<div className="px-2 py-1 rounded font-semibold bg-blue-100 text-blue-700">
											<Field field="status.articleStatus" />
										</div>
									</If>
									<If condition="[status.articleStatus = approved]">
										<div className="px-2 py-1 rounded font-semibold bg-yelllow-100 text-yellow-700">
											<Field field="status.articleStatus" />
										</div>
									</If>
									<If condition="[status.articleStatus = inReview]">
										<div className="px-2 py-1 rounded font-semibold bg-orange-200 text-orange-700">
											<Field field="status.articleStatus" />
										</div>
									</If>
									<div className="bg-gray-100 px-2 py-1 rounded flex-none font-semibold text-xs h-fit">Article</div>
								</div>
								<div className="px-2 py-1 rounded font-semibold bg-amber-200 text-amber-700 text-xs h-fit">
									<Field field="category.name" />
								</div>
								<Field
									field="publishedAt"
									format={value =>
										new Date(value as unknown as string).toLocaleString('cs-CZ', {
											hour: '2-digit',
											minute: '2-digit',
											year: 'numeric',
											month: 'numeric',
											day: 'numeric',
										})
									}
								/>{' '}
								- <Field field="title" />
							</div>
						</If>
						<If condition={`[podcast.id != ${null}]`}>
							<div className="flex text-left gap-4">
								<div className="flex-none text-xs h-fit flex gap-1">
									<If condition="[status.articleStatus = published]">
										<div className="px-2 py-1 rounded font-semibold bg-lime-200 text-lime-700">
											<Field field="status.articleStatus" />
										</div>
									</If>
									<If condition="[status.articleStatus = draft]">
										<div className="px-2 py-1 rounded font-semibold bg-blue-100 text-blue-700">
											<Field field="status.articleStatus" />
										</div>
									</If>
									<If condition="[status.articleStatus = approved]">
										<div className="px-2 py-1 rounded font-semibold bg-yelllow-100 text-yellow-700">
											<Field field="status.articleStatus" />
										</div>
									</If>
									<If condition="[status.articleStatus = inReview]">
										<div className="px-2 py-1 rounded font-semibold bg-orange-200 text-orange-700">
											<Field field="status.articleStatus" />
										</div>
									</If>
									<div className="bg-gray-100 px-2 py-1 rounded font-semibold flex-none text-xs h-fit">Podcast post</div>
								</div>
								<div className="px-2 py-1 rounded font-semibold bg-amber-200 text-amber-700 text-xs h-fit">
									<Field field="category.name" />
								</div>
								<Field
									field="publishedAt"
									format={value =>
										new Date(value as unknown as string).toLocaleString('cs-CZ', {
											hour: '2-digit',
											minute: '2-digit',
											year: 'numeric',
											month: 'numeric',
											day: 'numeric',
										})
									}
								/>{' '}
								- <Field field="podcast.title" />: <Field field="title" />
							</div>
						</If>
					</SelectField>
					<RepeaterItemActions>
						<RepeaterRemoveItemButton />
					</RepeaterItemActions>
				</DefaultRepeater>
			</DefaultRepeater>
		</Binding>
	)
}
