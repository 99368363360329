import { FormLayout, InputField, TextareaField } from '@app/lib/form'
import { SeoForm } from '@app/lib/plugins/seo'
import { SlugField } from '@app/lib/plugins/url'
import { Component } from '@contember/interface'
import { InfoIcon } from 'lucide-react'

export interface CategoryFormProps {
	over?: 'categoryUrl' | (string & {})
}

export const CategoryForm = Component(({ over }: CategoryFormProps) => (
	<FormLayout>
		<InputField field="name" label="Name" required />
		<TextareaField field="description" label="Description" required />
		<SlugField label="Url" field="url.url" derivedFrom="name" persistedHardPrefix="/" persistedSoftPrefix="rubriky/" />

		<div className="text-sm my-2 text-gray-600 bg-blue-100 py-4 pl-4 pr-10 flex gap-2 items-center w-fit">
			<InfoIcon size="16" />
			All related posts will be listed on the category page
		</div>
		<SeoForm field="seo" />
	</FormLayout>
))
