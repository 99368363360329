import { Component, Field } from '@contember/interface'

export const IssuePreview = Component(() => (
	<div className="flex gap-2 items-center">
		<span className="min-w-[62px] bg-gray-100 py-1 px-2 ">
			<Field field="number" /> / <Field field="year" />
		</span>{' '}
		<Field field="printTitle.title" />
	</div>
))
