import { useTableElement } from '@app/lib/editor/common/elements/TableElementRenderer'
import {
	ContextMenu,
	ContextMenuContent,
	ContextMenuItem,
	ContextMenuSeparator,
	ContextMenuTrigger,
} from '@app/lib/ui/context-menu'
import { cn } from '@app/lib/utils'
import { isTableElement, TableCellElement, TableModifications } from '@contember/react-slate-editor-base'
import { AlignCenterIcon, AlignLeftIcon, AlignRightIcon, PlusIcon, Trash2Icon } from 'lucide-react'
import { FC, PropsWithChildren, useCallback } from 'react'
import { Editor, Transforms } from 'slate'
import { ReactEditor, RenderElementProps, useSelected, useSlateStatic } from 'slate-react'

export interface TableCellElementRendererProps extends Omit<RenderElementProps, 'element'> {
	element: TableCellElement
	className?: string
}

const TableCellTrigger: FC<PropsWithChildren<TableCellElementRendererProps>> = ({ attributes, children, element, className }) => {
	const editor = useSlateStatic()
	const isSelected = useSelected()
	const tableElement = useTableElement()

	const path = ReactEditor.findPath(editor, element)
	const rowIndex = path[path.length - 2]
	const isHeader = tableElement?.children[rowIndex]?.headerScope === 'table'

	const commonProps = {
		...attributes,
		className: cn(className, 'border px-3 py-2', {
			'border-b-gray-500 border-b-2': isHeader,
			'outline-gray-400 outline-dashed': isSelected,
			'bg-gray-100': element?.headerScope,
			'text-left': element?.justify === 'start',
			'text-center': element?.justify === 'center',
			'text-right': element?.justify === 'end',
		}),
	}

	return <ContextMenuTrigger asChild>{isHeader ? <th {...commonProps}>{children}</th> : <td {...commonProps}>{children}</td>}</ContextMenuTrigger>
}

const TableCell: FC<PropsWithChildren<TableCellElementRendererProps>> = ({ element, attributes, children }) => {
	const editor = useSlateStatic()
	const tableElement = useTableElement()

	const path = ReactEditor.findPath(editor, element)
	const index = path[path.length - 1]
	const rowIndex = path[path.length - 2]

	const deleteTable = useCallback(() => {
		Promise.resolve()
			.then(() => {
				// The promise is a hack to avoid an exception when the table is the last element in the editor.
				// Slate also has an onClick handler somewhere that attempts to update the caret position but if our handler
				// deletes the table before that gets to run, it operates on stale props (I think) and ends up throwing.
				// So as a hacky workaround, we just let it do its thing and actually remove the table later.
				return Transforms.removeNodes(editor, {
					at: ReactEditor.findPath(editor, element),
					match: node => isTableElement(node),
				})
			})
			.catch(() => {})
	}, [editor, element])

	if (!tableElement) {
		return null
	}

	return (
		<ContextMenu>
			<TableCellTrigger element={element} attributes={attributes}>
				{children}
			</TableCellTrigger>
			<ContextMenuContent>
				<ContextMenuItem
					className="flex gap-2"
					onClick={() => {
						Editor.withoutNormalizing(editor, () => {
							TableModifications.toggleTableRowHeaderScope(
								editor,
								tableElement,
								0, // table header is always the first row
								tableElement.children[0].headerScope === 'table' ? undefined : 'table',
							)
						})
					}}
				>
					{tableElement.children[0].headerScope === 'table' ? 'Remove table header' : 'Set first row as table header'}
				</ContextMenuItem>
				<ContextMenuSeparator />
				<ContextMenuItem
					className="flex gap-2"
					onClick={() => {
						TableModifications.justifyTableColumn(editor, tableElement, index, 'start')
					}}
				>
					<AlignLeftIcon className="w-4 h-4" /> Align left
				</ContextMenuItem>
				<ContextMenuItem
					className="flex gap-2"
					onClick={() => {
						TableModifications.justifyTableColumn(editor, tableElement, index, 'center')
					}}
				>
					<AlignCenterIcon className="w-4 h-4" /> Align center
				</ContextMenuItem>
				<ContextMenuItem
					className="flex gap-2"
					onClick={() => {
						TableModifications.justifyTableColumn(editor, tableElement, index, 'end')
					}}
				>
					<AlignRightIcon className="w-4 h-4" /> Align right
				</ContextMenuItem>
				<ContextMenuSeparator />
				<ContextMenuItem
					className="flex gap-2"
					onClick={() => {
						TableModifications.addTableRow(editor, tableElement, rowIndex)
					}}
				>
					<PlusIcon className="w-4 h-4" /> Add row above
				</ContextMenuItem>
				<ContextMenuItem
					className="flex gap-2"
					onClick={() => {
						TableModifications.addTableRow(editor, tableElement, rowIndex + 1)
					}}
				>
					<PlusIcon className="w-4 h-4" /> Add row below
				</ContextMenuItem>
				<ContextMenuItem
					className="flex gap-2"
					onClick={() => {
						TableModifications.addTableColumn(editor, tableElement, index)
					}}
				>
					<PlusIcon className="w-4 h-4" /> Add column before
				</ContextMenuItem>
				<ContextMenuItem
					className="flex gap-2"
					onClick={() => {
						TableModifications.addTableColumn(editor, tableElement, index + 1)
					}}
				>
					<PlusIcon className="w-4 h-4" /> Add column after
				</ContextMenuItem>
				<ContextMenuSeparator />
				<ContextMenuItem
					className="flex gap-2"
					onClick={() => {
						TableModifications.deleteTableRow(editor, tableElement, rowIndex)
					}}
				>
					<Trash2Icon className="w-4 h-4" /> Delete row
				</ContextMenuItem>
				<ContextMenuItem
					className="flex gap-2"
					onClick={() => {
						TableModifications.deleteTableColumn(editor, tableElement, index)
					}}
				>
					<Trash2Icon className="w-4 h-4" /> Delete column
				</ContextMenuItem>
				<ContextMenuSeparator />
				<ContextMenuItem className="flex gap-2" onClick={deleteTable}>
					<Trash2Icon className="w-4 h-4" /> Delete table
				</ContextMenuItem>
			</ContextMenuContent>
		</ContextMenu>
	)
}

export function TableCellElementRenderer(props: TableCellElementRendererProps) {
	return <TableCell {...props} />
}
