import { Component, Field, If } from '@contember/interface'

export const ArticlePreview = Component(() => (
	<>
		<If condition={`[podcast.id = ${null}]`}>
			<div className="flex text-left gap-4">
				<div className="flex-none text-xs h-fit flex gap-1">
					<If condition="[status.articleStatus = published]">
						<div className="px-2 py-1 rounded font-semibold bg-lime-200 text-lime-700">
							<Field field="status.articleStatus" />
						</div>
					</If>
					<If condition="[status.articleStatus != published]">
						<div className="px-2 py-1 rounded font-semibold bg-orange-200 text-orange-700">Článek není publikován, nezobrazí se na webu</div>
					</If>
					<If condition={`[parentArticle.id != ${null}]`}>
						<div className="px-2 py-1 rounded font-semibold bg-pink-200 text-pink-700">Virtual</div>
					</If>
					<div className="bg-gray-100 px-2 py-1 rounded flex-none font-semibold text-xs h-fit">Article</div>
				</div>
				<Field
					field="publishedAt"
					format={value => {
						const date = new Date(value as unknown as string)
						const formattedDate = date.toLocaleString('cs-CZ', {
							hour: '2-digit',
							minute: '2-digit',
							year: 'numeric',
							month: 'numeric',
							day: 'numeric',
						})
						if (date > new Date()) {
							return <span className="text-blue-600 font-bold">zobrazí se v {formattedDate}</span>
						}
						return formattedDate
					}}
				/>{' '}
				- <Field field="title" />
			</div>
		</If>
		<If condition={`[podcast.id != ${null}]`}>
			<div className="flex text-left gap-4">
				<div className="flex-none text-xs h-fit flex gap-1">
					<If condition="[status.articleStatus = published]">
						<div className="px-2 py-1 rounded font-semibold bg-lime-200 text-lime-700">
							<Field field="status.articleStatus" />
						</div>
					</If>
					<If condition="[status.articleStatus != published]">
						<div className="px-2 py-1 rounded font-semibold bg-orange-200 text-orange-700">Článek není publikován, nezobrazí se na webu</div>
					</If>
					<div className="bg-gray-100 px-2 py-1 rounded font-semibold flex-none text-xs h-fit">Podcast</div>
				</div>
				<Field
					field="publishedAt"
					format={value => {
						const date = new Date(value as unknown as string)
						const formattedDate = date.toLocaleString('cs-CZ', {
							hour: '2-digit',
							minute: '2-digit',
							year: 'numeric',
							month: 'numeric',
							day: 'numeric',
						})
						if (date > new Date()) {
							return <span className="text-blue-600 font-bold">zobrazí se v {formattedDate}</span>
						}
						return formattedDate
					}}
				/>{' '}
				- <Field field="podcast.title" />: <Field field="title" />
			</div>
		</If>
	</>
))
