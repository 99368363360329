import { ConnectUser } from '@app/components/connect-user'
import { FeedbackTrigger } from '@app/lib/binding'
import { TextareaField } from '@app/lib/form'
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogTrigger,
} from '@app/lib/ui/alert-dialog'
import { Button } from '@app/lib/ui/button'
import { Component, Field, HasMany, PersistTrigger } from '@contember/interface'
import { MessageCirclePlus, PlusIcon } from 'lucide-react'

export const AddNoteButton = Component(
	() => {
		return (
			<AlertDialog>
				<AlertDialogTrigger asChild>
					<Button size="sm">
						<MessageCirclePlus className="h-4 w-4 mr-2" />
						Write comment
					</Button>
				</AlertDialogTrigger>
				<AlertDialogContent className="max-h-full overflow-y-auto">
					<HasMany field="comments" limit={0} initialEntityCount={1}>
						<TextareaField field="content" label="Comment" />
						{/* <ConnectUser field="author" /> */}
					</HasMany>
					<AlertDialogFooter>
						<AlertDialogCancel>Cancel</AlertDialogCancel>
						<FeedbackTrigger>
							<PersistTrigger>
								<AlertDialogAction>Save</AlertDialogAction>
							</PersistTrigger>
						</FeedbackTrigger>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		)
	},
	() => (
		<>
			<HasMany field="comments" limit={0} initialEntityCount={1}>
				<div>
					<Field field="content" />
				</div>
				{/* <ConnectUser field="author" /> */}
			</HasMany>
		</>
	),
)
