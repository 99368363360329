import { PageForm } from '@app/components/forms/page-form'
import { Binding, PersistButton } from '@app/lib/binding'
import { BackButton } from '@app/lib/buttons'
import { InputField } from '@app/lib/form'
import { Slots } from '@app/lib/layout'
import { EntitySubTree, RedirectOnPersist } from '@contember/interface'

export default () => {
	return (
		<>
			<Binding>
				<div className="flex flex-col gap-12">
					<Slots.Title>
						Page create
					</Slots.Title>
					<Slots.Back>
						<BackButton />
					</Slots.Back>
					<EntitySubTree entity="Page" isCreating>
						<RedirectOnPersist to="pageEdit(id: $entity.id)" />
						<Slots.Actions>
							<PersistButton />
						</Slots.Actions>
						<div className="grid grid-cols-[1fr,400px] gap-8">
							<PageForm />
							<div>
								<InputField field="publishedAt" label="Published at" />
								<InputField field="archivedAt" label="Archived at" />
							</div>
						</div>
					</EntitySubTree>
				</div>
			</Binding>
		</>
	)
}
