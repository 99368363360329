import { FocalPointDialogOpener, focalPointPoints, focalPointPreviews } from '@app/components/focal-point'
import { Binding } from '@app/lib/binding'
import {
	DataGrid,
	DataGridLoader,
	DataGridPagination,
	DataGridQueryFilter,
	DataGridTiles,
	DataGridToolbar,
} from '@app/lib/datagrid'
import { Field, FieldView, StaticRender } from '@contember/interface'

export const list = () => {
	return (
		<Binding>
			<DataGrid entities="Image">
				<DataGridToolbar>
					<DataGridQueryFilter />
				</DataGridToolbar>

				<DataGridLoader>
					<DataGridTiles>
						<FocalPointDialogOpener
							entity={'Image(id = $id)'}
							urlField={'url'}
							widthField={'width'}
							heightField={'height'}
							previews={focalPointPreviews}
							points={focalPointPoints}
						>
							<div>
								<button>
									<FieldView
										field="url"
										render={({ value: url }) => {
											return (
												<img
													src={url as string}
													width="500"
													height="500"
													className="w-64 aspect-square max-w-none border bg-gray-100 object-contain object-center hover:opacity-75"
												/>
											)
										}}
									/>
								</button>
							</div>

							<StaticRender>
								<Field field="meta.fileName" />
							</StaticRender>
						</FocalPointDialogOpener>
					</DataGridTiles>

					{/*<DataGridTable>*/}
					{/*	<DataGridColumn>*/}
					{/*		<FieldView*/}
					{/*			fields={['url', 'width', 'height']}*/}
					{/*			render={({ value: url }, { value: width }, { value: height }) => (*/}
					{/*				<Image*/}
					{/*					src={url as string}*/}
					{/*					width={width as number}*/}
					{/*					height={height as number}*/}
					{/*					resizeWidth={256}*/}
					{/*					className="w-64 max-w-none h-auto"*/}
					{/*				/>*/}
					{/*			)}*/}
					{/*		/>*/}
					{/*	</DataGridColumn>*/}
					{/*	<DataGridHasManyColumn header="Tagy" field="tags">*/}
					{/*		<Field field="name"/>*/}
					{/*	</DataGridHasManyColumn>*/}
					{/*	<DataGridHasOneColumn header="Autor" field="author">*/}
					{/*		<Field field="name"/>*/}
					{/*	</DataGridHasOneColumn>*/}
					{/*	<DataGridTextColumn field="title" header="Titulek"/>*/}
					{/*	<DataGridTextColumn field="fileName" header="Název souboru"/>*/}
					{/*	<DataGridTextColumn field="fileType" header="Typ souboru"/>*/}
					{/*	<DataGridTextColumn field="text" header="Popisek"/>*/}
					{/*	<DataGridDateColumn field="createdAt" header="Vytvořeno"/>*/}
					{/*</DataGridTable>*/}
				</DataGridLoader>

				<DataGridPagination />
			</DataGrid>
		</Binding>
	)
}
