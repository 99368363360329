import { DirectS3FileUploader } from '@app/components/direct-s3-upload'
import {
	CheckboxField,
	FormLayout,
	InputField,
	RadioEnumField,
	SelectField,
	SortableMultiSelectField,
	TextareaField,
	VideoField,
} from '@app/lib/form'
import { BlockEditorField } from '@app/lib/plugins/rich-text/editor'
import { SeoForm } from '@app/lib/plugins/seo/'
import { SlugField } from '@app/lib/plugins/url'
import { Card, CardContent } from '@app/lib/ui/card'
import { Component, Field, HasOne, If, StaticRender, useField } from '@contember/interface'
import { useEffect, useState } from 'react'
import { PodcastPreview } from '../previews/podcast-preview'
import { RichText } from '../rich-text'
import { PodcastForm } from './podcast-form'
import { ImageField } from '@app/lib/plugins/image/image-field'
import { RepeaterRemoveItemButton } from '@app/lib/repeater'
import { RepeaterItemActions } from '@app/lib/repeater'
import { DefaultRepeater } from '@app/lib/repeater'
import { StatusForm } from './status-form'
import { StatusPreview } from '../previews/status-preview'

export interface ArticleFormProps {
	formType?: 'article' | 'podcastPost'
	over?: 'articles' | 'article' | 'articleContent' | 'articleSubtitle' | 'articleSeo' | 'articleUrl' | (string & {})
}

const config = {
	accessKeyId: import.meta.env.VITE_AWS_ACCESS_KEY_ID,
	secretAccessKey: import.meta.env.VITE_AWS_SECRET_ACCESS_KEY,
	region: import.meta.env.VITE_AWS_REGION,
	bucket: import.meta.env.VITE_AWS_BUCKET,
}

const directS3Uploader = new DirectS3FileUploader({
	s3Config: config,
})

const endpointResolved = `https://${config.bucket}.s3.${config.region}.amazonaws.com`

const VideoStatusField = () => {
	const url = useField<string>('video.url')
	const [state, setState] = useState<'inProgress' | 'done' | 'waiting'>('waiting')

	const checkConvertStatus = async (urlValue: string, maxAttempts = 60 * 60 * 60) => {
		setState('inProgress')

		const replaceExt = (url: string, ext: string) => {
			const parts = url.split('.')
			parts.pop()
			return parts.join('.') + ext
		}
		const publicConvertedUrl = new URL(urlValue.replace(endpointResolved, import.meta.env.VITE_R2_PUBLIC_URL_BASE))
		publicConvertedUrl.pathname = `${publicConvertedUrl.pathname}${replaceExt(publicConvertedUrl.pathname, '.m3u8')}`

		let attempts = 0

		while (attempts < maxAttempts) {
			try {
				const response = await fetch(publicConvertedUrl)

				if (response.ok) {
					const contentType = response.headers.get('content-type')

					if (contentType?.includes('application/vnd.apple.mpegurl') || contentType?.includes('application/x-mpegurl')) {
						setState('done')
						return
					}
				}

				console.info(`Attempt ${attempts + 1}: Conversion still in progress...`)
				await new Promise(resolve => setTimeout(resolve, 3000))
				attempts++
			} catch (error) {
				console.error(`Attempt ${attempts + 1} failed:`, error)
				await new Promise(resolve => setTimeout(resolve, 2000))
				attempts++
			}
		}
	}

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (url.value) {
			checkConvertStatus(url.value)
		}
	}, [url.value])

	return (
		<>
			{state === 'inProgress' && <div className="text-red-700 font-bold">Video se transformuje...</div>}
			{state === 'done' && <div className="text-green-700 font-bold">Video transformováno. Zkontrolujte, že jste epizodu uložili!</div>}
		</>
	)
}

export const ArticleForm = Component(({ over, formType }: ArticleFormProps) => (
	<FormLayout>
		<InputField field="title" label="Title" required inputProps={{ className: 'max-w-full' }} />
		<If condition="[isOld != true]">
			<SlugField label="Url" field="url.url" derivedFrom="title" persistedHardPrefix="/" inputProps={{ className: 'max-w-full' }} />
		</If>
		<If condition="[isOld = true]">
			<InputField field="url.url" label="Url" inputProps={{ className: 'max-w-full' }} />
		</If>
		{formType === 'podcastPost' && (
			<>
				<SelectField field="podcast" label="Podcast *" createNewForm={<PodcastForm over="podcast" />}>
					<PodcastPreview />
				</SelectField>
				<VideoField
					uploader={directS3Uploader}
					baseField="video"
					urlField="url"
					durationField="duration"
					widthField="width"
					heightField="height"
					fileNameField="meta.fileName"
					fileSizeField="meta.fileSize"
					fileTypeField="meta.fileType"
					lastModifiedField="meta.lastModified"
					label="Video file"
				/>
				<VideoStatusField />
			</>
		)}
		<Card className="my-4">
			<CardContent>
				<div className="flex gap-6 w-fit">
					<div className="space-y-4">
						<ImageField label="Main image" baseField="image" excludePhotoBank={true} />
					</div>
					<div className="space-y-4">
						<ImageField label="Tile image" baseField="tileImage" excludePhotoBank={true} />
					</div>
					<RadioEnumField
						field="imageObjectFitType"
						label="Image object fit"
						defaultValue="cover"
						options={{ cover: 'Cover', contain: 'Contain' }}
						orientation="horizontal"
					/>
				</div>

				<If condition="[isOld != true]">
					<div className="flex gap-4">
						<HasOne field="authorList">
							<SortableMultiSelectField
								field="authors"
								label="Authors of images"
								connectAt="author"
								sortableBy="displayOrder"
								initialSorting={{ name: 'asc' }}
								options="Author[isPhotographer=true && isDeactivated=false]"
							>
								<Field field="name" />
							</SortableMultiSelectField>
						</HasOne>
						<InputField field="imageSource" label="Extra source of images" />
					</div>
				</If>
				<If condition="[isOld = true]">
					<InputField field="oldPhotographer" label="Photographer" />
					<InputField field="oldPhotographerText" label="Photographer text" />
				</If>
			</CardContent>
		</Card>
		<If condition="[isOld = true]">
			<InputField field="oldAuthor" label="Author" />
		</If>
		<RichText field="subtitle" label="Subtitle" />
		<If condition="[isOld != true]">
			<BlockEditorField field="content.data" referencesField="content.references" label="Content" />
		</If>
		{formType === 'podcastPost' && <TextareaField field="transcript" label="Transcript" />}
		<div className="my-6">
			<DefaultRepeater field="childrenArticle" title="Virtual articles" orderBy="createdAt">
				<CheckboxField field="isVirtualArticle" label="" defaultValue={true} inputProps={{ className: 'hidden' }} />
				<InputField field="title" label="Title" required inputProps={{ className: 'max-w-full' }} />
				<RichText field="subtitle" label="Subtitle" />
				<div className="flex gap-4 w-fit">
					<div className="space-y-4">
						<ImageField label="Main image" baseField="image" excludePhotoBank={true} />
					</div>
					<div className="space-y-4">
						<ImageField label="Tile image" baseField="tileImage" excludePhotoBank={true} />
					</div>
				</div>
				<InputField field="publishedAt" label="Published at" />
				<SelectField field="status" label="Status" createNewForm={<StatusForm over="status" />}>
					<StatusPreview />
				</SelectField>
				<RepeaterItemActions>
					<RepeaterRemoveItemButton />
				</RepeaterItemActions>
			</DefaultRepeater>
		</div>
		<SeoForm field="seo" />
	</FormLayout>
))
