import { S3Client, UploadPartCommand } from '@aws-sdk/client-s3'
import { Upload } from '@aws-sdk/lib-storage'
import { S3FileOptions, UploadClient, UploadClientUploadArgs, UploaderError } from '@contember/react-uploader'

class RetryingS3Client extends S3Client {
	public send = async (...args: any) => {
		if (args[0] instanceof UploadPartCommand) {
			let attempt = 0
			const uploadPart = async (): Promise<any> => {
				attempt++
				try {
					return super.send.apply(this, args as any)
				} catch (error) {
					if (attempt > 8) {
						console.warn('upload part failed after 8 attempts')
						throw error
					}
					console.warn('retrying upload part', attempt)
					console.warn(error)

					await new Promise(resolve => setTimeout(resolve, 1000 * (2 ** attempt)))
					return await uploadPart()
				}
			}
			return await uploadPart()
		}
		return super.send.apply(this, args as any)
	}
}

class DirectS3FileUploader implements UploadClient<DirectS3FileUploader.SuccessMetadata> {
	public constructor(public readonly options: DirectS3FileUploader.Options) {}

	public async upload({ file, signal, onProgress, ...options }: UploadClientUploadArgs & S3FileOptions) {
		const config = this.options.s3Config
		const s3 = new RetryingS3Client({
			credentials: {
				accessKeyId: config.accessKeyId,
				secretAccessKey: config.secretAccessKey,
			},
			region: config.region,
			endpoint: config.endpoint,
		})

		const nameWithRandomPrefix = `${Math.random().toString(36).substring(2)}-upload.${file.name.split('.').pop()}`
		const endpointResolved = config?.endpoint ?? `https://${config.bucket}.s3.${config.region}.amazonaws.com`

		const upload = new Upload({
			client: s3,
			leavePartsOnError: true,
			params: {
				Bucket: config.bucket,
				Key: nameWithRandomPrefix,
				Body: file,
			},
		})

		upload.on('httpUploadProgress', progress => {
			onProgress({
				progress: (progress.loaded ?? 0) / (progress.total ?? 1),
				uploadedBytes: progress.loaded ?? 0,
				totalBytes: progress.total ?? 1,
			})
		})

		await upload.done()

		// const checkConvertStatus = async (maxAttempts = 60 * 60 * 60) => {
		// 	let attempts = 0
		//
		// 	while (attempts < maxAttempts) {
		// 		try {
		// 			const response = await fetch(publicConvertedUrl)
		//
		// 			if (response.ok) {
		// 				const contentType = response.headers.get('content-type')
		//
		// 				if (contentType?.includes('application/vnd.apple.mpegurl') || contentType?.includes('application/x-mpegurl')) {
		// 					return
		// 				}
		// 			}
		//
		// 			console.info(`Attempt ${attempts + 1}: Conversion still in progress...`)
		// 			onProgress({
		// 				progress: attempts / maxAttempts,
		// 				uploadedBytes: attempts,
		// 				totalBytes: maxAttempts,
		// 			})
		// 			await new Promise(resolve => setTimeout(resolve, 3000))
		// 			attempts++
		// 		} catch (error) {
		// 			console.error(`Attempt ${attempts + 1} failed:`, error)
		//
		// 			if (attempts >= maxAttempts) {
		// 				throw new UploaderError({
		// 					error: 'Max conversion check attempts reached',
		// 					type: 'timeout',
		// 					developerMessage: 'Max conversion check attempts reached',
		// 					endUserMessage: 'Max conversion check attempts reached',
		// 				})
		// 			}
		//
		// 			await new Promise(resolve => setTimeout(resolve, 2000))
		// 			attempts++
		// 		}
		// 	}
		//
		// 	throw new UploaderError({
		// 		error: 'Max conversion check attempts reached',
		// 		type: 'timeout',
		// 		developerMessage: 'Max conversion check attempts reached',
		// 		endUserMessage: 'Max conversion check attempts reached',
		// 	})
		// }

		// await checkConvertStatus()

		return {
			publicUrl: `${endpointResolved}/${nameWithRandomPrefix}`,
		}
	}
}

namespace DirectS3FileUploader {
	export interface SuccessMetadata {
		fileUrl: string
	}

	export interface Options {
		concurrency?: number
		s3Config: {
			accessKeyId: string
			secretAccessKey: string
			region: string
			bucket: string
			endpoint?: string
		}
	}
}

export { DirectS3FileUploader }
