import { CategoryForm } from '@app/components/forms/category-form'
import { CategoryPreview } from '@app/components/previews/category-preview'
import { FormLayout, InputField, SelectField, TextareaField } from '@app/lib/form'
import { ImageField } from '@app/lib/plugins/image/image-field'
import { SeoForm } from '@app/lib/plugins/seo'
import { SlugField } from '@app/lib/plugins/url'
import { Component } from '@contember/interface'

export interface PodcastFormProps {
	over?: 'podcasts' | 'podcastUrl' | 'podcastImage' | (string & {})
}

export const PodcastForm = Component(({ over }: PodcastFormProps) => (
	<FormLayout>
		<div className="bg-white shadow-md rounded-lg p-6">
			<div className="grid grid-cols-1 md:grid-cols-3 gap-8 align-end">
				<div className="space-y-4">
					<InputField field="title" label="Title" required />
					<SlugField label="Url" field="url.url" derivedFrom="title" persistedHardPrefix="/" persistedSoftPrefix="porad/" />
					<TextareaField field="subtitle" label="Subtitle" />
					{over !== 'podcasts' && (
						<SelectField field="category" label="Category" createNewForm={<CategoryForm over="category" />}>
							<CategoryPreview />
						</SelectField>
					)}
				</div>

				<div className="space-y-4">
					<ImageField label="Image" baseField="image" />
				</div>

				<div className="space-y-4">
					<InputField field="youtubeLink" label="Youtube link" />
					<InputField field="spotifyLink" label="Spotify link" />
					<InputField field="appleLink" label="Apple link" />
				</div>
			</div>
		</div>
		<SeoForm field="seo" />
	</FormLayout>
))
