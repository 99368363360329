import { Button } from '@app/lib/ui/button'
import { Divider } from '@app/lib/ui/divider'
import { Menu, MenuItem } from '@app/lib/ui/menu'
import { Component, HasRole } from '@contember/interface'
import {
	NewspaperIcon,
	PodcastIcon,
	SquareUserIcon,
	SquareKanbanIcon,
	ArrowUpToLine,
	ArrowDownToLine,
	PanelsTopLeft,
	TagIcon,
	ListIcon,
	KeyRoundIcon,
	LockKeyholeIcon,
	UsersIcon,
	SendIcon,
	ImagesIcon,
} from 'lucide-react'

export const Navigation = Component(() => (
	<div className="my-4">
		<Menu>
			<HasRole role="admin">
				<Button className="w-fit py-1 px-0 mb-2 [&_a]:h-[36px] [&_a]:w-full">
					<MenuItem label="HP custom lists" icon={<ListIcon />} to="topThreeList" />
				</Button>
			</HasRole>

			<MenuItem label="Board" icon={<SquareKanbanIcon />} to="board" />
			<HasRole role="admin">
				<MenuItem label="Podcasts" icon={<PodcastIcon />} to="podcastList" />
			</HasRole>
			<MenuItem label="Articles" icon={<NewspaperIcon />} to="articleList" />
			<HasRole role="admin">
				<MenuItem label="Pages" icon={<PanelsTopLeft />} to="pageList" />

				<MenuItem label="Authors" icon={<SquareUserIcon />} to="authorList" />
				<MenuItem label="Categories" icon={<ListIcon />} to="categoryList" />
				<MenuItem label="Tags" icon={<TagIcon />} to="tagList" />
				<MenuItem label="Print titles" icon={<NewspaperIcon />} to="printTitleList" />
				<MenuItem label="Media library" icon={<ImagesIcon />} to="media/list" />

				<Divider className="mt-3 mb-2" />

				<MenuItem label="Header" to="header" icon={<ArrowUpToLine />} />
				<MenuItem label="Footer" to="footer" icon={<ArrowDownToLine />} />

				<Divider className="mt-3 mb-2" />

				<MenuItem icon={<SendIcon />} label={'Form submissions'} to={'formSubmission/list'} />
				<MenuItem icon={<UsersIcon />} label={'Members'} to={'tenant/members'} />
				<MenuItem icon={<KeyRoundIcon />} label={'API key'} to={'tenant/apiKeys'} />
			</HasRole>
			<MenuItem icon={<LockKeyholeIcon />} label={'Change password'} to={'tenant/security'} />
		</Menu>
	</div>
))
