import { cn } from '@app/lib/utils'
import {
	isTableElement,
	TableCellElement,
	TableElement,
	TableModifications,
	TableRowElement,
} from '@contember/react-slate-editor-base'
import { assertNever } from '@contember/utilities'
import { MinusCircleIcon, PlusCircleIcon } from 'lucide-react'
import { FC, memo, PropsWithChildren, useCallback } from 'react'
import { Editor, Transforms } from 'slate'
import { ReactEditor, RenderElementProps, useSelected, useSlateStatic } from 'slate-react'
import { BlockElement } from './BlockElement'

export const useTableElement = () => {
	const editor = useSlateStatic()
	const firstTable = editor.children.find(isTableElement)

	if (!editor.selection) {
		return firstTable
	}

	const selectedTableEntry = Editor.above(editor, {
		at: editor.selection,
		match: node => isTableElement(node),
	})

	if (!selectedTableEntry) {
		return firstTable
	}

	const [selectedTable] = selectedTableEntry
	return selectedTable
}

export interface TableElementRendererProps extends Omit<RenderElementProps, 'element'> {
	element: TableElement
}

type ToggleRowHeaderScope = (index: number, scope: TableRowElement['headerScope']) => void
type ToggleColumnHeaderScope = (index: number, scope: TableCellElement['headerScope']) => void

interface TableProps {
	rowCount: number
	columnCount: number
	extendTable: (vector: 'row' | 'column', index?: number) => void
	shrinkTable: (vector: 'row' | 'column', index: number) => void
	toggleRowHeaderScope: ToggleRowHeaderScope
	toggleColumnHeaderScope: ToggleColumnHeaderScope
	justifyColumn: (index: number, direction: TableCellElement['justify']) => void
	deleteTable: () => void
	selectTable: () => void
	isSelected: boolean
	isFocused: boolean
}

interface TableControlButtonProps {
	onClick: () => void
	icon: React.ReactNode
	className?: string
	title: string
}

interface TableActionBarProps {
	toggleRowHeaderScope: ToggleRowHeaderScope
	toggleColumnHeaderScope: ToggleColumnHeaderScope
	justifyColumn: (index: number, direction: TableCellElement['justify']) => void
}

interface TableResizeControlsProps {
	direction: 'row' | 'column'
	index: number
	isEnd?: boolean
	shrinkTable: (vector: 'row' | 'column', index: number) => void
	extendTable: (vector: 'row' | 'column', index?: number) => void
}

const TableControlButton: FC<TableControlButtonProps> = ({ onClick, icon, className, title }) => (
	<button
		onClick={onClick}
		className={cn('opacity-0 group-hover:opacity-100 transition-opacity duration-200', 'hover:bg-gray-200 rounded p-1', className)}
		title={title}
	>
		{icon}
	</button>
)

const TableActionBar: FC<TableActionBarProps> = ({ toggleRowHeaderScope, toggleColumnHeaderScope, justifyColumn }) => (
	<div className="flex gap-2 p-2 bg-gray-50 rounded-t border-b" contentEditable={false}>
		{/*<div className="flex items-center gap-2">*/}
		{/*	<select onChange={e => toggleRowHeaderScope(0, e.target.value)} className="select select-sm">*/}
		{/*		<option value="">Row Header</option>*/}
		{/*		<option value="table">Table Scope</option>*/}
		{/*	</select>*/}

		{/*	<select onChange={e => toggleColumnHeaderScope(0, e.target.value)} className="select select-sm">*/}
		{/*		<option value="">Column Header</option>*/}
		{/*		<option value="row">Row Scope</option>*/}
		{/*	</select>*/}
		{/*</div>*/}

		<div className="flex items-center gap-2 ml-4">
			<span className="text-sm text-gray-500">Alignment:</span>
			{[
				{ value: 'start', icon: '←' },
				{ value: 'center', icon: '↔' },
				{ value: 'end', icon: '→' },
			].map(({ value, icon }) => (
				<button
					key={value}
					onClick={() => justifyColumn(1, value as 'center' | 'start' | 'end')}
					className="p-1 hover:bg-gray-200 rounded"
					title={`Align ${value}`}
				>
					{icon}
				</button>
			))}
		</div>
	</div>
)

const TableResizeControls: FC<TableResizeControlsProps> = ({ direction, index, isEnd = false, shrinkTable, extendTable }) => {
	const containerClassName = cn(
		'flex gap-1 p-1 group',
		'bg-gray-50 hover:bg-gray-100 transition-colors duration-200',
		direction === 'column' ? 'flex-col items-center justify-center' : 'items-center justify-center',
	)

	return (
		<div className={containerClassName}>
			<TableControlButton
				onClick={() => shrinkTable(direction, index)}
				icon={<MinusCircleIcon className="w-4 h-4 text-gray-500 hover:text-red-500" />}
				title={`Remove ${direction}`}
			/>
			<TableControlButton
				onClick={() => extendTable(direction, isEnd ? undefined : index)}
				icon={<PlusCircleIcon className="w-4 h-4 text-gray-500 hover:text-green-500" />}
				title={`Add ${direction}`}
			/>
		</div>
	)
}

const Table: FC<PropsWithChildren<TableProps>> = ({ isSelected, columnCount, rowCount, deleteTable, children, ...actionProps }) => {
	return (
		<div className="relative border rounded shadow-sm">
			<table className="w-full border-collapse">
				<tbody>{children}</tbody>
			</table>
		</div>
	)
}

export const TableElementRenderer = memo(function TableElementRenderer(props: TableElementRendererProps) {
	const editor = useSlateStatic()
	const isSelected = useSelected()
	const isFocused = false

	const extendTable = useCallback(
		(vector: 'row' | 'column', index?: number) => {
			if (vector === 'row') {
				TableModifications.addTableRow(editor, props.element, index)
			} else if (vector === 'column') {
				TableModifications.addTableColumn(editor, props.element, index)
			} else {
				assertNever(vector)
			}
		},
		[editor, props.element],
	)
	const shrinkTable = useCallback(
		(vector: 'row' | 'column', index: number) => {
			if (vector === 'row') {
				TableModifications.deleteTableRow(editor, props.element, index)
			} else if (vector === 'column') {
				TableModifications.deleteTableColumn(editor, props.element, index)
			} else {
				assertNever(vector)
			}
		},
		[editor, props.element],
	)
	const toggleRowHeaderScope = useCallback(
		(index: number, scope: TableRowElement['headerScope']) => {
			TableModifications.toggleTableRowHeaderScope(editor, props.element, index, scope)
		},
		[editor, props.element],
	)
	const toggleColumnHeaderScope = useCallback(
		(index: number, scope: TableCellElement['headerScope']) => {
			TableModifications.toggleTableColumnHeaderScope(editor, props.element, index, scope)
		},
		[editor, props.element],
	)

	const justifyColumn = useCallback(
		(index: number, direction: TableCellElement['justify']) => TableModifications.justifyTableColumn(editor, props.element, index, direction),
		[editor, props.element],
	)

	const selectTable = useCallback(() => {
		Transforms.select(editor, ReactEditor.findPath(editor, props.element))
	}, [editor, props.element])

	const deleteTable = useCallback(() => {
		Promise.resolve()
			.then(() => {
				// The promise is a hack to avoid an exception when the table is the last element in the editor.
				// Slate also has an onClick handler somewhere that attempts to update the caret position but if our handler
				// deletes the table before that gets to run, it operates on stale props (I think) and ends up throwing.
				// So as a hacky workaround, we just let it do its thing and actually remove the table later.
				return Transforms.removeNodes(editor, {
					at: ReactEditor.findPath(editor, props.element),
					match: node => isTableElement(node),
				})
			})
			.catch(() => {})
	}, [editor, props.element])
	return (
		<BlockElement element={props.element} attributes={props.attributes} withBoundaries>
			<div className="py-4">
				<Table
					rowCount={props.element.children.length}
					columnCount={(props.element.children[0] as TableRowElement | undefined)?.children.length ?? 0}
					extendTable={extendTable}
					shrinkTable={shrinkTable}
					toggleRowHeaderScope={toggleRowHeaderScope}
					toggleColumnHeaderScope={toggleColumnHeaderScope}
					justifyColumn={justifyColumn}
					selectTable={selectTable}
					deleteTable={deleteTable}
					isSelected={isSelected}
					isFocused={isFocused}
				>
					{props.children}
				</Table>
			</div>
		</BlockElement>
	)
})
