import { HeaderForm } from '@app/components/forms/header-form'
import { Binding, PersistButton } from '@app/lib/binding'
import { Slots } from '@app/lib/layout'
import { EntitySubTree } from '@contember/interface'

export default () => {
	return (
		<Binding>
			<Slots.Title>Header</Slots.Title>
			<Slots.Actions>
				<PersistButton />
			</Slots.Actions>
			<EntitySubTree entity="Header(unique = 'one')" setOnCreate="(unique = 'one')">
				<HeaderForm />
			</EntitySubTree>
		</Binding>
	)
}
