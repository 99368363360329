import { FormLayout, InputField, RadioEnumField, SelectField } from '@app/lib/form'
import { ImageField } from '@app/lib/plugins/image/image-field'
import { Component } from '@contember/interface'
import { SelectLinkField } from './select-link-field'

export const HeaderLinkForm = Component(({ noIcon }: { noIcon?: boolean }) => (
	<FormLayout>
		<div className={noIcon ? '' : 'grid grid-cols-2 gap 4'}>
			<div className={noIcon ? 'grid grid-cols-2 gap 4' : ''}>
				<InputField field="title" label="Title" />
				<SelectLinkField field="link" label="Link" />
			</div>
			{!noIcon && <ImageField label="Icon" baseField="icon" />}
		</div>
	</FormLayout>
))
