import { imageFields, SelectImage } from '@app/components/select-image'
import { dict } from '@app/lib/dict'
import { ImageField as DefaultImageField } from '@app/lib/form'
import { Button } from '@app/lib/ui/button'
import { UploaderDropzoneAreaUI } from '@app/lib/upload'
import { Component } from '@contember/interface'
import { UploadIcon } from 'lucide-react'

export const ImageField = Component<{ baseField: string; label?: string; excludePhotoBank?: boolean }>(props => {
	return (
		<DefaultImageField
			label={props.label}
			baseField={props.baseField}
			{...imageFields}
			dropzonePlaceholder={
				<UploaderDropzoneAreaUI className="w-60">
					<UploadIcon className={'w-12 h-12 text-gray-400'} />
					<div className={'font-semibold text-sm'}>{dict.uploader.dropFiles}</div>
					<div className={'text-xs'}>{dict.uploader.or}</div>
					<div className={'flex gap-2 items-center text-xs'}>
						<Button size={'sm'} variant={'outline'}>
							{dict.uploader.browseFiles}
						</Button>
						<div onClick={e => e.stopPropagation()}>
							<SelectImage excludePhotoBank={props?.excludePhotoBank} />
						</div>
					</div>
				</UploaderDropzoneAreaUI>
			}
		/>
	)
})
