import { PrintTitleForm } from '@app/components/forms/print-title-form'
import { Binding, DeleteEntityDialog, PersistButton } from '@app/lib/binding'
import { BackButton } from '@app/lib/buttons'
import {
	DataGrid,
	DataGridColumn,
	DataGridDateColumn,
	DataGridDateTimeColumn,
	DataGridHasOneColumn,
	DataGridLoader,
	DataGridNumberColumn,
	DataGridPagination,
	DataGridQueryFilter,
	DataGridTable,
	DataGridTextColumn,
	DataGridToolbar,
} from '@app/lib/datagrid'
import { ImageField } from '@app/lib/plugins/image/image-field'
import { Slots } from '@app/lib/layout'
import { Button } from '@app/lib/ui/button'
import { EntitySubTree, Field, Link } from '@contember/interface'
import { PenIcon, TrashIcon } from 'lucide-react'
import { formatDateTimeCz } from '@app/lib/formatting'

export default () => {
	return (
		<>
			<Binding>
				<div className="flex flex-col gap-12">
					<Slots.Title>Print title edit</Slots.Title>
					<Slots.Back>
						<BackButton />
					</Slots.Back>
					<EntitySubTree entity="PrintTitle(id=$id)" isCreating={false}>
						<Slots.Actions>
							<PersistButton />
						</Slots.Actions>
						<PrintTitleForm />
					</EntitySubTree>

					<div className="flex flex-col gap-4">
						<div className="flex justify-between">
							<div className="text-lg font-bold">Print title issues</div>
							<Link to="issueCreate">
								<Button>Create issue</Button>
							</Link>
						</div>
						<DataGrid
							entities="Issue[printTitle.id = $id]"
							initialSorting={{ publicationDate: 'desc' }}
							initialFilters={{ year: { from: new Date().getFullYear(), to: new Date().getFullYear() } }}
						>
							<DataGridToolbar>
								<DataGridQueryFilter />
							</DataGridToolbar>
							<DataGridLoader>
								<DataGridTable>
									<DataGridColumn>
										<div className="flex gap-4">
											<Link to="issueEdit(id: $entity.id)">
												<Button variant="secondary">
													<PenIcon size="14" />
												</Button>
											</Link>
										</div>
									</DataGridColumn>
									<DataGridColumn>
										<ImageField baseField="cover" />
									</DataGridColumn>
									<DataGridTextColumn field="number" header="Number" format={value => <strong>{value}</strong>} />
									<DataGridNumberColumn field="year" header="Year" format={value => value} />
									<DataGridDateTimeColumn field="publicationDate" header="Publication Date" format={formatDateTimeCz} />
									<DataGridColumn>
										<div className="flex justify-end items-center gap-4">
											<DeleteEntityDialog
												trigger={
													<Button variant="destructive" size="sm">
														<TrashIcon className="w-3 h-3" />
													</Button>
												}
											/>
										</div>
									</DataGridColumn>
								</DataGridTable>
							</DataGridLoader>
							<DataGridPagination />
						</DataGrid>
					</div>
				</div>
			</Binding>
		</>
	)
}
