import { ArticleForm } from '@app/components/forms/article-form'
import { PodcastForm } from '@app/components/forms/podcast-form'
import { FormLayout, InputField, RadioEnumField } from '@app/lib/form'
import { DefaultRepeater, RepeaterItemActions, RepeaterRemoveItemButton } from '@app/lib/repeater'
import { Component } from '@contember/interface'

export interface StatusFormProps {
	over?: 'status' | (string & {})
}

export const StatusForm = Component(({ over }: StatusFormProps) => (
	<FormLayout>
		<InputField field="name" label="Name" required />
		<RadioEnumField
			field="articleStatus"
			label="Article status"
			options={{ draft: 'draft', inReview: 'inReview', approved: 'approved', published: 'published' }}
		/>
		{over !== 'status' && (
			<DefaultRepeater field="articles" title="Articles" orderBy="createdAt">
				<RepeaterItemActions>
					<RepeaterRemoveItemButton />
				</RepeaterItemActions>
				<ArticleForm over="articles" />
			</DefaultRepeater>
		)}
		{over !== 'status' && (
			<DefaultRepeater field="podcasts" title="Podcasts" orderBy="createdAt">
				<RepeaterItemActions>
					<RepeaterRemoveItemButton />
				</RepeaterItemActions>
				<PodcastForm over="podcasts" />
			</DefaultRepeater>
		)}
	</FormLayout>
))
