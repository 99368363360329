import { ArticleForm } from '@app/components/forms/article-form'
import { CommentsSection } from '@app/components/forms/comments'
import { StatusPreview } from '@app/components/previews/status-preview'
import { StatusForm } from '@app/components/forms/status-form'
import { Binding, PersistButton } from '@app/lib/binding'
import { BackButton } from '@app/lib/buttons'
import { CheckboxField, InputField, MultiSelectField, SelectField } from '@app/lib/form'
import { Slots } from '@app/lib/layout'
import { EntitySubTree } from '@contember/interface'
import { CategoryForm } from '@app/components/forms/category-form'
import { AuthorForm } from '@app/components/forms/author-form'
import { IssueForm } from '@app/components/forms/issue-form'
import { CategoryPreview } from '@app/components/previews/category-preview'
import { IssuePreview } from '@app/components/previews/issue-preview'
import { AuthorPreview } from '@app/components/previews/author-preview'
import { PreviewButton } from '@app/components/previewButton'
import { TagsPreview } from '@app/components/previews/tags-preview.'
import { TagForm } from '@app/components/forms/tag-form.'
import { Divider } from '@app/lib/ui/divider'

export default () => {
	return (
		<>
			<Binding>
				<div className="flex flex-col gap-12">
					<Slots.Title>Article edit</Slots.Title>
					<Slots.Back>
						<BackButton />
					</Slots.Back>
					<EntitySubTree entity="Article(id=$id)" isCreating={false}>
						<Slots.Actions>
							<PreviewButton />
							<PersistButton />
						</Slots.Actions>
						<div className="grid grid-cols-[1fr,400px] gap-8">
							<ArticleForm formType="article" />
							<div>
								<InputField field="publishedAt" label="Published at" />
								<InputField field="actualizedAt" label="Actualized at" />
								<InputField field="archivedAt" label="Archived at" />
								<CheckboxField field="notHomepageListed" label="Not visible on homepage" />
								<CheckboxField field="isInSeznamCZRssFeed" label="Zobrazit v Seznam.cz RSS feedu" />
								<Divider className="my-4" />
								<SelectField field="status" label="Status" createNewForm={<StatusForm over="status" />}>
									<StatusPreview />
								</SelectField>
								<SelectField
									field="author"
									label="Author"
									createNewForm={<AuthorForm over="author" />}
									initialSorting={{ name: 'asc' }}
									options="Author[isPhotographer=false && isDeactivated=false]"
								>
									<AuthorPreview />
								</SelectField>
								<SelectField field="category" initialSorting={{ name: 'asc' }} label="Category" createNewForm={<CategoryForm over="category" />}>
									<CategoryPreview />
								</SelectField>
								<MultiSelectField field="tags" initialSorting={{ name: 'asc' }} label="Tags" createNewForm={<TagForm />}>
									<TagsPreview />
								</MultiSelectField>
								<SelectField
									field="issue"
									label="Issue"
									options="Issue[printTitle.isPublished=true]"
									createNewForm={<IssueForm over="issue" />}
									initialSorting={{ 'printTitle.title': 'asc', year: 'desc', number: 'desc' }}
								>
									<IssuePreview />
								</SelectField>
								<div className="my-4">
									<CommentsSection entityName="article" />
								</div>
							</div>
						</div>
					</EntitySubTree>
				</div>
			</Binding>
		</>
	)
}
