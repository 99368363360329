import { TableRowElement } from '@contember/react-slate-editor-base'
import { memo } from 'react'
import type { RenderElementProps } from 'slate-react'

export interface TableRowElementRendererProps extends Omit<RenderElementProps, 'element'> {
	element: TableRowElement
}

const TableRow = ({ element, attributes, children }: TableRowElementRendererProps) => {
	return <tr {...attributes}>{children}</tr>
}

export const TableRowElementRenderer = memo(function TableRowElementRenderer(props: TableRowElementRendererProps) {
	return <TableRow {...props} key={props.element.headerScope} />
})
