import { FormLayout, InputField } from '@app/lib/form'
import { Component } from '@contember/interface'
import { SelectLinkField } from './select-link-field'
import { ImageField } from '@app/lib/plugins/image/image-field'

export const SocialMediaForm = Component(() => (
	<FormLayout>
		<div className="flex gap-4">
			<div>
				<ImageField label="Icon" baseField="icon" />
			</div>
			<div className="w-full">
				<InputField field="platform" label="Platform" required />
				<SelectLinkField field="link" label="Link" />
			</div>
		</div>
	</FormLayout>
))
