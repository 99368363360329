import { useField } from '@contember/react-binding'
import { Button } from '@app/lib/ui/button'
import { ExternalLinkIcon } from 'lucide-react'

export const PreviewButton = ({ icon = false }) => {
	const id = useField<string>('id').value!

	const PREVIEW_DOMAIN = 'https://tyden-cz.vercel.app'
	return (
		<a href={`${PREVIEW_DOMAIN}/preview/${id}`} target="_blank">
			<Button variant="outline" className="flex gap-2 mr-3">
				{icon ? (
					<ExternalLinkIcon size="14" />
				) : (
					<>
						Preview <ExternalLinkIcon size="14" />
					</>
				)}
			</Button>
		</a>
	)
}
