import { CheckboxField, FormLayout, InputField, TextareaField } from '@app/lib/form'
import { Component } from '@contember/interface'

export interface AuthorFormProps {
	over?: 'author' | (string & {})
}

export const AuthorForm = Component(({ over }: AuthorFormProps) => (
	<FormLayout>
		<InputField field="name" label="Name" required />
		<CheckboxField field="isPhotographer" label="Author of photos" />
		<CheckboxField field="isDeactivated" label="Deactivated" />
		<TextareaField field="bio" label="Bio" required />
	</FormLayout>
))
