import { FormLayout } from '@app/lib/form'
import { ImageField } from '@app/lib/plugins/image/image-field'
import { DefaultRepeater, RepeaterItemActions, RepeaterRemoveItemButton } from '@app/lib/repeater'
import { Component, HasOne } from '@contember/interface'
import { FooterLinkForm } from './footer-link-form'
import { RichText } from '../rich-text'
import { SocialMediaForm } from './social-media-form'

export const FooterForm = Component(() => (
	<FormLayout>
		<ImageField label="Logo" baseField="image" />
		<DefaultRepeater field="socialMedia" title="Social media" sortableBy="displayOrder">
			<RepeaterItemActions>
				<RepeaterRemoveItemButton />
			</RepeaterItemActions>
			<SocialMediaForm />
		</DefaultRepeater>

		<HasOne field="leftLinkList">
			<DefaultRepeater field="links" title="Left link list" sortableBy="displayOrder">
				<FooterLinkForm />
				<RepeaterItemActions>
					<RepeaterRemoveItemButton />
				</RepeaterItemActions>
			</DefaultRepeater>
		</HasOne>

		<HasOne field="rightLinkList">
			<DefaultRepeater field="links" title="Right link links" sortableBy="displayOrder">
				<FooterLinkForm noIcon />
				<RepeaterItemActions>
					<RepeaterRemoveItemButton />
				</RepeaterItemActions>
			</DefaultRepeater>
		</HasOne>
		<div className="my-6">
			<RichText field="primaryText" label="Primary text" />
			<RichText field="secondaryText" label="Secondary text" />
		</div>
	</FormLayout>
))
