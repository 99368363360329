import { EntitySubTree, RedirectOnPersist } from "@contember/interface";
import { DataGridLoader } from "@app/lib/datagrid";

import { Binding } from "@app/lib/binding";
import { Component } from "@contember/interface";
import { DataGrid, DataGridQueryFilter, DataGridToolbar } from "@app/lib/datagrid";
import { AddNoteButton } from "./add-note-button";
import { DataGridNotes } from "./note";
import { NoteCard } from "./notecard";

export const CommentsSection = Component(({ entityName }: { entityName: 'article' | 'podcast' }) => (
	<div className="bg-gray-50/50 w-full border rounded-md h-fit px-4 py-4 flex flex-col gap-4">
		<div className="flex items-center justify-between">
			<h2 className="font-semibold ">Comments</h2>
			<Binding>
				<EntitySubTree entity={entityName === 'article' ? 'Article(id=$id)' : 'Podcast(id=$id)'} isCreating={false}>
					<RedirectOnPersist to={entityName === 'article' ? 'articleEdit(id:$entity.id)' : 'podcastEdit(id:$entity.id)'} />
					<AddNoteButton />
				</EntitySubTree>
			</Binding>
		</div>
		<div className="flex flex-col gap-4">
			<DataGrid entities={entityName === 'article' ? 'ArticleComment[article.id = $id]' : 'ArticleComment[podcast.id = $id]'} initialSorting={{ createdAt: 'desc' }}>
				<DataGridToolbar>
					<DataGridQueryFilter />
				</DataGridToolbar>
				<DataGridLoader>
					<DataGridNotes>
						<NoteCard />
					</DataGridNotes>
				</DataGridLoader>
			</DataGrid>
		</div>
	</div>
))
