import { SelectLinkField } from '@app/components/forms/select-link-field'
import { RichText } from '@app/components/rich-text'
import {
	BlockEditorField as BlockEditorFieldBase,
	BlockEditorFieldProps as BlockEditorFieldPropsBase,
	EditorBlock,
	EditorBlockToolbar,
	EditorInlineToolbar,
} from '@app/lib/editor'
import { CheckboxField, InputField, RadioEnumField, SelectEnumField, SelectField, TextareaField } from '@app/lib/form'
import { DefaultRepeater, RepeaterItemActions, RepeaterRemoveItemButton } from '@app/lib/repeater'
import { Button } from '@app/lib/ui/button'
import { Toggle } from '@app/lib/ui/toggle'
import { uic } from '@app/lib/utils'
import { Component, FieldView, HasOne, If } from '@contember/interface'
import {
	anchorElementType,
	boldMark,
	codeMark,
	createAlignHandler,
	EditorElementTrigger,
	EditorGenericTrigger,
	EditorMarkTrigger,
	EditorReferenceTrigger,
	EditorRenderElementProps,
	EditorTransforms,
	headingElementType,
	highlightMark,
	italicMark,
	orderedListElementType,
	paragraphElementType,
	strikeThroughMark,
	tableElementType,
	underlineMark,
	unorderedListElementType,
} from '@contember/react-slate-editor'
import { Popover, PopoverContent, PopoverTrigger } from '@radix-ui/react-popover'
import {
	AlignCenterIcon,
	AlignJustifyIcon,
	AlignLeftIcon,
	AlignRightIcon,
	BoldIcon,
	ClipboardIcon,
	CodeIcon,
	CodeXml,
	FileQuestion,
	Heading2Icon,
	Heading3Icon,
	Heading4Icon,
	Heading5Icon,
	HighlighterIcon,
	ImageIcon,
	ImagesIcon,
	InfoIcon,
	ItalicIcon,
	Link2Icon,
	LinkIcon,
	ListIcon,
	ListOrderedIcon,
	Music,
	NotebookPen,
	PilcrowIcon,
	Podcast,
	QuoteIcon,
	RectangleEllipsisIcon,
	StrikethroughIcon,
	TableIcon,
	TrashIcon,
	UnderlineIcon,
	Youtube,
} from 'lucide-react'
import * as React from 'react'
import { useSlateStatic } from 'slate-react'
import { ImageRepeaterField } from '../image-repeater-field'
import { ImageField } from '../image/image-field'
import { PodcastPreview } from '@app/components/previews/podcast-preview'
import { ArticlePreview } from '@app/components/previews/article-preview'
import { PodcastPostPreview } from '@app/components/previews/podcast-post-preview'

export interface BlockEditorFieldProps {
	field: BlockEditorFieldPropsBase['field']
	referencesField: BlockEditorFieldPropsBase['referencesField']
	label?: string
}

const BlockButton = uic('button', {
	baseClass: 'bg-white p-2 m-[2px] inline-flex flex-col hover:bg-gray-100 border rounded-md w-32 items-center justify-center',
})

const LinkElement = (props: EditorRenderElementProps) => {
	const editor = useSlateStatic()
	return (
		<span {...props.attributes}>
			<span className="bg-gray-50 border-b border-b-blue-300">{props.children}</span>
			<span contentEditable={false}>
				<Popover>
					<PopoverTrigger asChild>
						<button className="hover:bg-gray-200 p-1.5 border rounded">
							<LinkIcon className="w-2 h-2" />
						</button>
					</PopoverTrigger>
					<PopoverContent>
						<div className="flex gap-2 items-center">
							<SelectLinkField field="link" />

							<Button
								onClick={() =>
									EditorTransforms.unwrapNodes(editor, {
										at: [],
										match: node => node === props.element,
									})
								}
								variant="destructive"
								size="sm"
							>
								<TrashIcon className="w-3 h-3" />
							</Button>
						</div>
					</PopoverContent>
				</Popover>
			</span>
		</span>
	)
}

export const BlockEditorField = Component(({ field, referencesField, label }: BlockEditorFieldProps) => (
	<div className="mr-4 flex flex-col gap-2">
		{label && <div>{label}</div>}
		<BlockEditorFieldBase
			field={field}
			referencesField={referencesField}
			referenceDiscriminationField="type"
			plugins={[
				editor => {
					editor.registerElement({
						type: 'link',
						isInline: true,
						render: LinkElement,
					})
				},
			]}
		>
			<EditorBlockToolbar>
				<div className="flex flex-wrap">
					<EditorReferenceTrigger referenceType="image">
						<BlockButton>
							<ImageIcon /> Image
						</BlockButton>
					</EditorReferenceTrigger>

					<EditorReferenceTrigger referenceType="gallery">
						<BlockButton>
							<ImagesIcon /> Gallery
						</BlockButton>
					</EditorReferenceTrigger>

					<EditorReferenceTrigger referenceType="highlight">
						<BlockButton>
							<HighlighterIcon /> Highlight
						</BlockButton>
					</EditorReferenceTrigger>
					<EditorReferenceTrigger referenceType="quote">
						<BlockButton>
							<QuoteIcon /> Quote
						</BlockButton>
					</EditorReferenceTrigger>
					<EditorReferenceTrigger referenceType="youtubeVideo">
						<BlockButton>
							<Youtube /> Youtube
						</BlockButton>
					</EditorReferenceTrigger>
					<EditorReferenceTrigger referenceType="soundcloud">
						<BlockButton>
							<Music /> Soundcloud
						</BlockButton>
					</EditorReferenceTrigger>
					<EditorReferenceTrigger referenceType="html">
						<BlockButton>
							<CodeXml /> HTML
						</BlockButton>
					</EditorReferenceTrigger>
					<EditorReferenceTrigger referenceType="form">
						<BlockButton>
							<FileQuestion /> Form
						</BlockButton>
					</EditorReferenceTrigger>
					<EditorReferenceTrigger referenceType="interview">
						<BlockButton>
							<NotebookPen /> Interview
						</BlockButton>
					</EditorReferenceTrigger>
					<EditorElementTrigger elementType={tableElementType}>
						<BlockButton>
							<TableIcon /> Table
						</BlockButton>
					</EditorElementTrigger>
					<EditorReferenceTrigger referenceType="podcast">
						<BlockButton>
							<Podcast /> Podcast
						</BlockButton>
					</EditorReferenceTrigger>
					<EditorReferenceTrigger referenceType="articleTile">
						<BlockButton>
							<RectangleEllipsisIcon /> Article tile
						</BlockButton>
					</EditorReferenceTrigger>
				</div>
			</EditorBlockToolbar>

			<EditorInlineToolbar>
				<div>
					<EditorMarkTrigger mark={boldMark}>
						<Toggle>
							<BoldIcon className="h-3 w-3" />
						</Toggle>
					</EditorMarkTrigger>
					<EditorMarkTrigger mark={italicMark}>
						<Toggle>
							<ItalicIcon className="h-3 w-3" />
						</Toggle>
					</EditorMarkTrigger>
					<EditorMarkTrigger mark={underlineMark}>
						<Toggle>
							<UnderlineIcon className="h-3 w-3" />
						</Toggle>
					</EditorMarkTrigger>
					<EditorMarkTrigger mark={strikeThroughMark}>
						<Toggle>
							<StrikethroughIcon className="h-3 w-3" />
						</Toggle>
					</EditorMarkTrigger>
					<EditorMarkTrigger mark={highlightMark}>
						<Toggle>
							<HighlighterIcon className="h-3 w-3" />
						</Toggle>
					</EditorMarkTrigger>
					<EditorMarkTrigger mark={codeMark}>
						<Toggle>
							<CodeIcon className="h-3 w-3" />
						</Toggle>
					</EditorMarkTrigger>
					<EditorElementTrigger elementType={anchorElementType}>
						<Toggle>
							<Link2Icon className="h-3 w-3" />
						</Toggle>
					</EditorElementTrigger>
				</div>
				<div>
					<EditorElementTrigger elementType={paragraphElementType} suchThat={{ isNumbered: false }}>
						<Toggle>
							<PilcrowIcon className="h-3 w-3" />
						</Toggle>
					</EditorElementTrigger>

					<EditorElementTrigger elementType={headingElementType} suchThat={{ level: 2, isNumbered: false }}>
						<Toggle>
							<Heading2Icon className="h-3 w-3" />
						</Toggle>
					</EditorElementTrigger>
					<EditorElementTrigger elementType={headingElementType} suchThat={{ level: 3, isNumbered: false }}>
						<Toggle>
							<Heading3Icon className="h-3 w-3" />
						</Toggle>
					</EditorElementTrigger>
					<EditorElementTrigger elementType={headingElementType} suchThat={{ level: 4, isNumbered: false }}>
						<Toggle>
							<Heading4Icon className="h-3 w-3" />
						</Toggle>
					</EditorElementTrigger>
					<EditorElementTrigger elementType={headingElementType} suchThat={{ level: 5, isNumbered: false }}>
						<Toggle>
							<Heading5Icon className="h-3 w-3" />
						</Toggle>
					</EditorElementTrigger>
					<EditorElementTrigger elementType={unorderedListElementType}>
						<Toggle>
							<ListIcon className="h-3 w-3" />
						</Toggle>
					</EditorElementTrigger>
					<EditorElementTrigger elementType={orderedListElementType}>
						<Toggle>
							<ListOrderedIcon className="h-3 w-3" />
						</Toggle>
					</EditorElementTrigger>

					<EditorGenericTrigger {...createAlignHandler('start')}>
						<Toggle className="ml-4">
							<AlignLeftIcon className="h-3 w-3" />
						</Toggle>
					</EditorGenericTrigger>
					<EditorGenericTrigger {...createAlignHandler('end')}>
						<Toggle>
							<AlignRightIcon className="h-3 w-3" />
						</Toggle>
					</EditorGenericTrigger>
					<EditorGenericTrigger {...createAlignHandler('center')}>
						<Toggle>
							<AlignCenterIcon className="h-3 w-3" />
						</Toggle>
					</EditorGenericTrigger>
					<EditorGenericTrigger {...createAlignHandler('justify')}>
						<Toggle>
							<AlignJustifyIcon className="h-3 w-3" />
						</Toggle>
					</EditorGenericTrigger>
				</div>
			</EditorInlineToolbar>

			<EditorBlock name="image" label="Image">
				<ImageField label="Image" baseField="image" excludePhotoBank={true} />
			</EditorBlock>

			<EditorBlock name="gallery" label="Gallery">
				<SelectEnumField field="gallery.type" label="Typ" options={{ carousel: 'Carousel', grid: 'Grid' }} />
				<InputField field="heading" label="Heading" />
				<ImageRepeaterField field="gallery.items" label="Gallery" />
			</EditorBlock>

			<EditorBlock name="highlight" label="Highlight">
				<InputField field="heading" label="Heading" />
				<RichText field="text" label="Text" />
			</EditorBlock>

			<EditorBlock name="youtubeVideo" label="Youtube video">
				<InputField field="heading" label="Embed url" />
			</EditorBlock>

			<EditorBlock name="articleTile" label="Article tile">
				<SelectField field="article" label="Article">
					<ArticlePreview />
				</SelectField>
			</EditorBlock>

			<EditorBlock name="html" label="HTML">
				<div className="font-mono">
					<TextareaField field="text" label="HTML" />
				</div>
			</EditorBlock>

			<EditorBlock name="soundcloud" label="Soundcloud">
				<InputField field="heading" label="Embed url" />
				<p className="text-gray-700 text-[11px] font-thin">
					Example of EMBED url:
					<br />
					<span className="break-words italic">
						https://w.soundcloud.com/player/?visual=false&url=https%3A%2F%2Fapi.soundcloud.com%2Ftracks%2F1867975344&show_artwork=true&show_comments=false
					</span>
				</p>
			</EditorBlock>

			<EditorBlock name="podcast" label="Podcast post">
				<InputField field="heading" label="Title" />
				<SelectField
					field="article"
					label="Podcast post"
					options={{
						entityName: 'Article',
						filter: { podcast: { id: { isNull: false } } },
					}}
					initialSorting={{ publishedAt: 'desc' }}
				>
					<ArticlePreview />
				</SelectField>
			</EditorBlock>

			<EditorBlock name="quote" label="Quote">
				<InputField field="heading" label="Heading" />
				<InputField field="text" label="Small text (author etc)" />
			</EditorBlock>

			<EditorBlock name="form" label="Form">
				<HasOne field="form">
					<InputField field="title" label="Title" />
					<CheckboxField field="isActive" label="Formulář je aktivní" defaultValue={true} />
					<If condition="[isActive = false]">
						<div className="text-sm w-fit my-2 text-red-600 rounded bg-red-100 p-4 flex gap-2 items-center">
							<InfoIcon size="16" />
							Pozor, neaktivníformulář nebude vidět na webu.
						</div>
					</If>
					<DefaultRepeater title="Inputs" field="items" sortableBy="displayOrder">
						<div className="text-sm w-fit my-2 text-gray-600 rounded bg-lime-100 p-4 flex gap-2 items-center">
							<InfoIcon size="16" />
							E-mail field is contained in every form as default on last position.
						</div>
						<CheckboxField field="required" label="Required" defaultValue={false} />
						<InputField field="label" label="Otázka/ popis u inputu" required />
						<InputField field="name" label="Pole (vypsání v db, např. věk, email atd.)" required />
						<RadioEnumField field="type" label="Type" options={{ text: 'Text', number: 'Number', checkbox: 'Checkbox', radio: 'Radio button' }} />
						<If condition="[type = checkbox || type = radio]">
							<DefaultRepeater title="Choices" field="choices" sortableBy="displayOrder">
								<InputField field="name" label="Name" />
								<RepeaterItemActions>
									<RepeaterRemoveItemButton />
								</RepeaterItemActions>
							</DefaultRepeater>
						</If>
						<If condition="[type = number]">
							<CheckboxField field="onlyPositiveNumber" label="Positive numbers only" defaultValue={false} />
						</If>
						<RepeaterItemActions>
							<RepeaterRemoveItemButton />
						</RepeaterItemActions>
					</DefaultRepeater>
					<InputField field="successText" label="Form success message" />
				</HasOne>
			</EditorBlock>

			<EditorBlock name="interview" label="Interview">
				<div className="flex flex-col gap-2">
					<HasOne field="interview">
						<InputField field="title" label="Title" />
						<TextareaField field="subtitle" label="Description" />
					</HasOne>

					<HasOne field="interview">
						<DefaultRepeater title="Questions and answers" field="questions" sortableBy="displayOrder">
							<InputField field="name" label="Name" />
							<InputField field="question" label="Question" />
							<TextareaField field="answer" label="Answer" />
							<CheckboxField field="declined" label="Declined" />
							<RepeaterItemActions>
								<RepeaterRemoveItemButton />
							</RepeaterItemActions>
						</DefaultRepeater>
						<div className="border-t">
							<CheckboxField field="answersVisible" label="Interview is completed (answers are visible)" />
							<If condition="[answersVisible = true]">
								<InputField field="answerTitle" label="Nadpis po uzavření" required />
							</If>
							<InputField field="password" label="Password for asnwer filling" required />
						</div>
					</HasOne>

					<FieldView
						field="content.articleContent.url.url"
						render={({ value: url }) => {
							const websiteUrl = import.meta.env.VITE_WEBSITE_URL
							const copyToClipboard = () => {
								if (url) {
									navigator.clipboard.writeText(`${websiteUrl}${url}?answering`)
								}
							}

							return (
								<div className="flex items-center">
									<Button variant="secondary" className="flex gap-2 items-center" onClick={copyToClipboard}>
										<ClipboardIcon className="h-4 w-4" /> Zkopírovat odkaz pro hosta
									</Button>
								</div>
							)
						}}
					/>
				</div>
			</EditorBlock>
		</BlockEditorFieldBase>
	</div>
))
