import { Binding, DeleteEntityDialog } from '@app/lib/binding'
import { BackButton } from '@app/lib/buttons'
import {
	DataGrid,
	DataGridBooleanColumn,
	DataGridColumn,
	DataGridHasManyColumn,
	DataGridLoader,
	DataGridNumberColumn,
	DataGridPagination,
	DataGridQueryFilter,
	DataGridTable,
	DataGridTextColumn,
	DataGridToolbar,
} from '@app/lib/datagrid'
import { Slots } from '@app/lib/layout'
import { AnchorButton, Button } from '@app/lib/ui/button'
import { Field, Link } from '@contember/interface'
import { EditIcon, TrashIcon } from 'lucide-react'

export default () => {
	return (
		<>
			<Binding>
				<div className="flex flex-col gap-12">
					<Slots.Title>Print title list</Slots.Title>
					<Slots.Back>
						<BackButton />
					</Slots.Back>
					<>
						<Slots.Actions>
							<Link to="printTitleOrder">
								<AnchorButton variant="secondary">Order print title</AnchorButton>
							</Link>
							<Link to="printTitleCreate">
								<AnchorButton>Create print title</AnchorButton>
							</Link>
						</Slots.Actions>
						<DataGrid entities="PrintTitle" initialFilters={{ isPublished: { includeTrue: true } }} initialSorting={{ title: 'asc' }}>
							<DataGridToolbar>
								<DataGridQueryFilter />
							</DataGridToolbar>
							<DataGridLoader>
								<DataGridTable>
									<DataGridColumn>
										<div className="flex gap-4">
											<Link to="printTitleEdit(id: $entity.id)">
												<AnchorButton variant="secondary">
													<EditIcon size="14" />
												</AnchorButton>
											</Link>
										</div>
									</DataGridColumn>
									<DataGridTextColumn field="title" header="Title" format={value => <strong>{value}</strong>} />
									<DataGridNumberColumn field="number" header="Number" />
									<DataGridBooleanColumn field="isPublished" header="Is published" />

									<DataGridColumn>
										<div className="flex justify-end items-center gap-4">
											<DeleteEntityDialog
												trigger={
													<Button variant="destructive" size="sm">
														<TrashIcon className="w-3 h-3" />
													</Button>
												}
											/>
										</div>
									</DataGridColumn>
								</DataGridTable>
							</DataGridLoader>
							<DataGridPagination />
						</DataGrid>
					</>
				</div>
			</Binding>
		</>
	)
}
