import { Editor, withSortable } from '@contember/react-slate-editor'
import { HtmlDeserializerPlugin } from '@contember/react-slate-editor-base'
import { baseEditorPlugins } from './common/baseEditorPlugins'
import { SortableBlock } from './sortable-block'

const TableHTMLDeserializer: HtmlDeserializerPlugin = {
	processBlockPaste: ({ element, next, cumulativeTextAttrs }) => {
		if (element.tagName === 'TABLE') {
			return [
				{
					type: 'table',
					children: next(element.childNodes, cumulativeTextAttrs),
				},
			]
		}

		if (element.tagName === 'TR') {
			return [
				{
					type: 'tableRow',
					children: next(element.childNodes, cumulativeTextAttrs),
				},
			]
		}

		if (element.tagName === 'TD' || element.tagName === 'TH') {
			return [
				{
					type: 'tableCell',
					children: next(element.childNodes, cumulativeTextAttrs),
				},
			]
		}

		return null
	},
}

const htmlTableDeserializerPlugin = (editor: Editor) => {
	editor.htmlDeserializer.registerPlugin(TableHTMLDeserializer)

}

const plugins = baseEditorPlugins

export const richTextFieldPlugins = [
	plugins.anchor,
	plugins.bold,
	plugins.code,
	plugins.highlight,
	plugins.italic,
	plugins.newline,
	plugins.strikeThrough,
	plugins.underline,
]

export const blockEditorPlugins = [
	plugins.anchor,
	plugins.paragraph,
	plugins.heading,
	plugins.list,
	plugins.horizontalRule,
	plugins.scrollTarget,
	plugins.table,
	htmlTableDeserializerPlugin,
	plugins.bold,
	plugins.code,
	plugins.highlight,
	plugins.italic,
	plugins.newline,
	plugins.strikeThrough,
	plugins.underline,
	withSortable({
		render: SortableBlock,
	}),
]
