import { PrintTitleForm } from '@app/components/forms/print-title-form'
import { PrintTitlePreview } from '@app/components/previews/print-title-preview'
import { RichTextField } from '@app/lib/editor/rich-text'
import { FormLayout, InputField, SelectField } from '@app/lib/form'
import { ImageField } from '@app/lib/plugins/image/image-field'
import { Component } from '@contember/interface'
import { RichText } from '../rich-text'

export interface IssueFormProps {
	over?: 'issue' | 'issues' | 'issueCover' | (string & {})
}

export const IssueForm = Component(({ over }: IssueFormProps) => (
	<FormLayout>
		<div className="flex gap-4">
			<InputField field="number" label="Number (neměnit, používá se pro načítání titulek)" required />
			<InputField field="year" label="Year" />
			<InputField field="publicationDate" label="Publication date" />
		</div>
		{over !== 'issues' && (
			<SelectField field="printTitle" options="PrintTitle[isPublished=true]" label="Print title" createNewForm={<PrintTitleForm over="printTitle" />}>
				<PrintTitlePreview />
			</SelectField>
		)}
		<ImageField label="Cover" baseField="cover" />
		<RichText field="issueContent" label="Issue content" />
		<InputField field="subscriptionUrlPaper" label="Odkaz na předplatné v tištěném formátu" />
		<InputField field="subscriptionUrlDigital" label="Odkaz na předplatné v digitálním formátu" />
	</FormLayout>
))
