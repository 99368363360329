import { PreviewButton } from '@app/components/previewButton'
import { Binding, DeleteEntityDialog } from '@app/lib/binding'
import { BackButton } from '@app/lib/buttons'
import {
	DataGrid,
	DataGridColumn,
	DataGridDateTimeColumn,
	DataGridHasOneColumn,
	DataGridLoader,
	DataGridPagination,
	DataGridQueryFilter,
	DataGridTable,
	DataGridTextColumn,
	DataGridToolbar,
} from '@app/lib/datagrid'
import { Slots } from '@app/lib/layout'
import { AnchorButton, Button } from '@app/lib/ui/button'
import { Field, Link } from '@contember/interface'
import { EditIcon, TrashIcon } from 'lucide-react'

export default () => {
	return (
		<>
			<Binding>
				<div className="flex flex-col gap-12">
					<Slots.Title>Articles</Slots.Title>
					<Slots.Back>
						<BackButton />
					</Slots.Back>
					<>
						<Slots.Actions>
							<Link to="articleCreate">
								<AnchorButton>Create article</AnchorButton>
							</Link>
						</Slots.Actions>
						<DataGrid entities={`Article[podcast.id = ${null} && parentArticle.id = ${null}]`} initialSorting={{ publishedAt: 'desc' }}>
							<DataGridToolbar>
								<DataGridQueryFilter />
							</DataGridToolbar>
							<DataGridLoader>
								<DataGridTable>
									<DataGridColumn cellClassName='max-w-fit p-0 pl-2'>
										<div className="flex gap-4">
											<Link to="articleEdit(id: $entity.id)">
												<AnchorButton variant="secondary">
													<EditIcon size="14" />
												</AnchorButton>
											</Link>
											<PreviewButton icon />
										</div>
									</DataGridColumn>
									<DataGridTextColumn field="title" header="Title" />
									<DataGridDateTimeColumn
										field="publishedAt"
										header="Published at"
										format={value =>
											new Date(value as unknown as string).toLocaleString('cs-CZ', {
												hour: '2-digit',
												minute: '2-digit',
												year: 'numeric',
												month: 'numeric',
												day: 'numeric',
											})
										}
									/>
									<DataGridHasOneColumn field="category" header="Category">
										<Field field="name" />
									</DataGridHasOneColumn>
									<DataGridHasOneColumn field="status" header="Status">
										<Field field="name" />
									</DataGridHasOneColumn>
									<DataGridColumn>
										<div className="flex justify-end items-center gap-4">
											<DeleteEntityDialog
												trigger={
													<Button variant="destructive" size="sm">
														<TrashIcon className="w-3 h-3" />
													</Button>
												}
											/>
										</div>
									</DataGridColumn>
								</DataGridTable>
							</DataGridLoader>
							<DataGridPagination />
						</DataGrid>
					</>
				</div>
			</Binding>
		</>
	)
}
