import { Binding, PersistButton } from '@app/lib/binding'
import { Slots } from '@app/lib/layout'
import { DefaultRepeater } from '@app/lib/repeater'
import { Field } from '@contember/interface'

export default () => {
	return (
		<>
			<Binding>
				<Slots.Title>Pořadí titulů</Slots.Title>
				<Slots.Actions>
					<PersistButton />
				</Slots.Actions>
				<DefaultRepeater entities="PrintTitle" sortableBy="displayOrder">
					<Field field="title" />
				</DefaultRepeater>
			</Binding>
		</>
	)
}
