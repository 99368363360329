import { ArticlePreview } from '@app/components/previews/article-preview'
import { Binding, PersistButton } from '@app/lib/binding'
import { RadioEnumField, SelectField } from '@app/lib/form'
import { Slots } from '@app/lib/layout'
import { DefaultRepeater, RepeaterItemActions, RepeaterRemoveItemButton } from '@app/lib/repeater'
import { Field } from '@contember/interface'
import { EntityListSubTree } from '@contember/interface'

export default () => {
	return (
		<Binding>
			<Slots.Title>Homepage custom lists</Slots.Title>
			<Slots.Actions>
				<PersistButton />
			</Slots.Actions>
			<h3 className="font-bold text-2xl mt-4 mb-6">Hero top 3 blocks on homepage</h3>
			<EntityListSubTree entities="ArticleList[type=hero]">
				<div className="my-4 hidden">
					<RadioEnumField
						field="type"
						label="Type"
						options={{
							hero: 'Hero top 3 posts',
							// trends: 'Trends',
							// recommended: 'Recommended',
						}}
						orientation="horizontal"
					/>
				</div>
				<DefaultRepeater field="articles" title="" sortableBy="displayOrder">
					<SelectField field="article" label="Post" initialSorting={{ publishedAt: 'desc' }}>
						<ArticlePreview />
					</SelectField>
					<RepeaterItemActions>
						<RepeaterRemoveItemButton />
					</RepeaterItemActions>
				</DefaultRepeater>
			</EntityListSubTree>

			<div className="py-8">
				<h3 className="font-bold text-2xl mt-4 mb-6">Trendy blocks on homepage</h3>
				<EntityListSubTree entities="Block[type=trends && list.pageBlocks.type=homepage]">
					<div className="mt-3 mb-8">
						<div className="flex flex-col gap-2 bg-sky-50 rounded-md p-2 mb-2 font-semibold">
							<Field field="title" />
						</div>
						<DefaultRepeater field="articles" title="Posts" sortableBy="displayOrder">
							<SelectField field="article" label="Post" initialSorting={{ publishedAt: 'desc' }}>
								<ArticlePreview />
							</SelectField>
							<RepeaterItemActions>
								<RepeaterRemoveItemButton />
							</RepeaterItemActions>
						</DefaultRepeater>
					</div>
				</EntityListSubTree>
			</div>
			<div className="py-8">
				<h3 className="font-bold text-2xl mt-4 mb-6">Recommended blocks on homepage</h3>
				<EntityListSubTree entities="Block[type=recommended && list.pageBlocks.type=homepage]">
					<div className="mt-3 mb-8">
						<div className="flex flex-col gap-2 rounded-md p-2 mb-2 font-semibold bg-sky-50">
							<Field field="title" />
						</div>
						<DefaultRepeater field="articles" title="Posts" sortableBy="displayOrder">
							<SelectField field="article" label="Post" initialSorting={{ publishedAt: 'desc' }}>
								<ArticlePreview />
							</SelectField>
							<RepeaterItemActions>
								<RepeaterRemoveItemButton />
							</RepeaterItemActions>
						</DefaultRepeater>
					</div>
				</EntityListSubTree>
			</div>
		</Binding>
	)
}
