import { ArticleForm } from '@app/components/forms/article-form'
import { CategoryForm } from '@app/components/forms/category-form'
import { StatusForm } from '@app/components/forms/status-form'
import { CategoryPreview } from '@app/components/previews/category-preview'
import { StatusPreview } from '@app/components/previews/status-preview'
import { Binding, PersistButton } from '@app/lib/binding'
import { BackButton } from '@app/lib/buttons'
import { InputField, SelectField } from '@app/lib/form'
import { Slots } from '@app/lib/layout'
import { EntitySubTree } from '@contember/interface'

export default () => {
	return (
		<>
			<Binding>
				<div className="flex flex-col gap-12">
					<Slots.Title>Podcast post edit</Slots.Title>
					<Slots.Back>
						<BackButton />
					</Slots.Back>
					<EntitySubTree entity="Article(id=$id)" isCreating={false}>
						<Slots.Actions>
							<PersistButton />
						</Slots.Actions>
						<div className="grid grid-cols-[1fr,400px] gap-8">
							<ArticleForm formType="podcastPost" />
							<div>
								<InputField field="publishedAt" label="Published at" />
								<InputField field="archivedAt" label="Archived at" />
								<SelectField field="status" label="Status" createNewForm={<StatusForm over="status" />}>
									<StatusPreview />
								</SelectField>
							</div>
						</div>
					</EntitySubTree>
				</div>
			</Binding>
		</>
	)
}
