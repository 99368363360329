import { FormLayout, InputField } from '@app/lib/form'
import { SeoForm } from '@app/lib/plugins/seo'
import { SlugField } from '@app/lib/plugins/url'

import { Component } from '@contember/interface'
import { InfoIcon } from 'lucide-react'

export const TagForm = Component(() => (
	<FormLayout>
		<InputField field="name" label="Name" required />
		<SlugField label="Url" field="url.url" derivedFrom="name" persistedHardPrefix="/" persistedSoftPrefix="tema/" />

		<div className="text-sm my-2 text-gray-600 bg-blue-100 py-4 pl-4 pr-10 flex gap-2 items-center w-fit">
			<InfoIcon size="16" />
			All related posts will be listed on the tag page
		</div>
		<SeoForm field="seo" />
	</FormLayout>
))
