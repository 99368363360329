import { CheckboxField, FormLayout, InputField, RadioEnumField } from '@app/lib/form'
import { DefaultRepeater, RepeaterItemActions, RepeaterRemoveItemButton } from '@app/lib/repeater'
import { Component, HasOne, If } from '@contember/interface'
import { InfoIcon } from 'lucide-react'

export const FormForm = Component(() => (
	<FormLayout>
		<HasOne field="form">
			<InputField field="title" label="Title" />
			<CheckboxField field="isActive" label="Formulář je aktivní" defaultValue={true} />
			<If condition="[isActive = false]">
				<div className="text-sm w-fit my-2 text-red-600 rounded bg-red-100 p-4 flex gap-2 items-center">
					<InfoIcon size="16" />
					Pozor, neaktivníformulář nebude vidět na webu.
				</div>
			</If>
			<DefaultRepeater title="Inputs" field="items" sortableBy="displayOrder">
				<div className="text-sm w-fit my-2 text-gray-600 rounded bg-lime-100 p-4 flex gap-2 items-center">
					<InfoIcon size="16" />
					E-mail field is contained in every form as default on last position.
				</div>
				<CheckboxField field="required" label="Required" defaultValue={false} />
				<InputField field="label" label="Otázka/ popis u inputu" required />
				<InputField field="name" label="Pole (vypsání v db, např. věk, email atd.)" required />
				<RadioEnumField field="type" label="Type" options={{ text: 'Text', number: 'Number', checkbox: 'Checkbox', radio: 'Radio button' }} />
				<If condition="[type = checkbox || type = radio]">
					<DefaultRepeater title="Choices" field="choices" sortableBy="displayOrder">
						<InputField field="name" label="Name" />
						<RepeaterItemActions>
							<RepeaterRemoveItemButton />
						</RepeaterItemActions>
					</DefaultRepeater>
				</If>
				<If condition="[type = number]">
					<CheckboxField field="onlyPositiveNumber" label="Positive numbers only" defaultValue={false} />
				</If>
				<RepeaterItemActions>
					<RepeaterRemoveItemButton />
				</RepeaterItemActions>
			</DefaultRepeater>
			<InputField field="successText" label="Form success message" />
		</HasOne>
	</FormLayout>
))
