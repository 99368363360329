import { FormLayout, InputField, RadioEnumField, SelectField, SortableMultiSelectField, TextareaField } from '@app/lib/form'
import { BlockRepeater } from '@app/lib/plugins/blocks'
import { SeoForm } from '@app/lib/plugins/seo/'
import { SlugField } from '@app/lib/plugins/url'
import { RepeaterRemoveItemButton } from '@app/lib/repeater'
import { DefaultRepeater, RepeaterItemActions } from '@app/lib/repeater'
import { Component, Field, FieldView, If } from '@contember/interface'
import { Block } from '@contember/react-block-repeater'
import { InfoIcon } from 'lucide-react'
import { SelectLinkField } from './select-link-field'
import { FormForm } from './form-form'
import { BlockEditorField } from '@app/lib/plugins/rich-text/editor'
import { ArticlePreview } from '../previews/article-preview'

export interface PageFormProps {
	over?: 'pageSeo' | 'pageUrl' | 'pageBlocks' | (string & {})
}

const decodeBlockType = (blockType: string) => {
	switch (blockType) {
		case 'top_3Articles':
			return 'Top 3 Posts'
		case 'mostRead':
			return 'Most Read'
		case 'recommended':
			return 'Recommended'
		case 'podcasts':
			return 'Podcasts'
		case 'trends':
			return 'Trends'
		case 'news':
			return 'News'
		case 'category':
			return 'Category'
		case 'form':
			return 'Form'
		case 'printTitles':
			return 'Print titles'
		case 'content':
			return 'Content'
		default:
			return blockType
	}
}

const BlockTypeTitle = Component(() => {
	return (
		<FieldView<string>
			field="type"
			render={blockType => {
				if (!blockType.value) {
					return null
				}
				return (
					<h3 className="text-xs mb-5 border-t-2 border-[#ED1C24] max-w-fit py-1 uppercase font-semibold text-[#ED1C24]">
						{decodeBlockType(blockType.value)}
					</h3>
				)
			}}
		/>
	)
})

export const postSelect = (
	<DefaultRepeater field="articles" title="Posts" sortableBy="displayOrder">
		<SelectField field="article" label="Post" initialSorting={{ publishedAt: 'desc' }}>
			<div className="px-2 py-1 rounded font-semibold bg-amber-200 text-amber-700 text-xs h-fit">
				<Field field="category.name" />
			</div>
			<If condition={`[podcast.id = ${null}]`}>
				<div className="bg-gray-100 px-2 py-1 rounded flex-none font-semibold text-xs h-fit">Article</div>
			</If>
			<If condition={`[podcast.id != ${null}]`}>
				<div className="bg-gray-100 px-2 py-1 rounded font-semibold flex-none text-xs h-fit">Podcast post</div>
			</If>
			<Field
				field="publishedAt"
				format={value =>
					new Date(value as unknown as string).toLocaleString('cs-CZ', {
						hour: '2-digit',
						minute: '2-digit',
						year: 'numeric',
						month: 'numeric',
						day: 'numeric',
					})
				}
			/>{' '}
			<Field field="title" />
		</SelectField>
		<RepeaterItemActions>
			<RepeaterRemoveItemButton />
		</RepeaterItemActions>
	</DefaultRepeater>
)

export const PageForm = Component(({ over }: PageFormProps) => (
	<FormLayout>
		<InputField field="title" label="Title" required />
		<SlugField label="Url" field="url.url" derivedFrom="title" persistedHardPrefix="/" />
		<RadioEnumField
			field="type"
			label="Type"
			options={{ genericPage: 'Generic Page', podcastList: 'Podcast list', articleList: 'Article list', homepage: 'Homepage' }}
			required
		/>
		<If condition="[type = podcastList]">
			<div className="text-sm w-fit my-2 text-gray-600 rounded bg-orange-100 p-4 flex gap-2 items-center">
				<InfoIcon size="16" />
				All podcasts will be automatically displayed on this page.
			</div>
		</If>
		<If condition="[type = articleList]">
			<div className="text-sm my-2 w-fit text-gray-600 rounded bg-yellow-100 p-4 flex gap-2 items-center">
				<InfoIcon size="16" />
				All articles will be automatically displayed on this page.
			</div>
		</If>
		<If condition="[type = genericPage || type = homepage]">
			<BlockRepeater field="blocks">
				<Block name="top_3Articles" label="Top 3 posts">
					<BlockTypeTitle />
					<div className="text-sm my-2 w-fit text-gray-600 rounded bg-red-100 p-4 flex gap-2 items-center">
						<InfoIcon size="16" />
						Nejnovější posty se vybírají v menu - HP custom lists
					</div>
				</Block>
				<Block name="mostRead" label="Most read">
					<BlockTypeTitle />
					<InputField field="title" label="Title" required />
					<div className="text-sm my-2 w-fit text-gray-600 rounded bg-violet-100 p-4 flex gap-2 items-center">
						<InfoIcon size="16" />
						Automaticky zobrazuje 6 nejčtenějších postů za posledních 7 dní.
					</div>
				</Block>
				<Block name="recommended" label="Recommended">
					<BlockTypeTitle />
					<InputField field="title" label="Title" required />
					<If condition="[type != genericPage]">
						<div className="text-sm my-2 w-fit text-gray-600 rounded bg-red-100 p-4 flex gap-2 items-center">
							<InfoIcon size="16" />
							Doporučené posty na Homepage se vybírají v menu - HP custom lists
						</div>
					</If>
					<If condition="[type = genericPage]">
						<SortableMultiSelectField
							field="articles"
							label="Articles"
							sortableBy="displayOrder"
							connectAt="article"
							description="Doporučené posty vyberte zde, nebo v menu - HP custom lists"
							initialSorting={{ publishedAt: 'desc' }}
						>
							<ArticlePreview />
						</SortableMultiSelectField>
					</If>
				</Block>
				<Block name="podcasts" label="Podcasts">
					<BlockTypeTitle />
					<InputField field="title" label="Title" required />
					<TextareaField field="description" label="Description" />
					<InputField field="text" label=" Button title" />
					<SelectLinkField field="primaryLink" label="Button link" />
					<SortableMultiSelectField field="podcasts" label="Podcasts" connectAt="podcast" sortableBy="displayOrder">
						<Field field="title" />
					</SortableMultiSelectField>
				</Block>
				<Block name="trends" label="Trendy">
					<BlockTypeTitle />
					<InputField field="title" label="Title" required />
					<InputField field="description" label="Description" />
					<div className="text-sm my-2 w-fit text-gray-600 rounded bg-red-100 p-4 flex gap-2 items-center">
						<InfoIcon size="16" />
						Trendy posty se vybírají v menu - HP custom lists
					</div>
				</Block>
				<Block name="content" label="Content">
					<BlockTypeTitle />
					<BlockEditorField field="content.data" referencesField="content.references" label="Content" />
				</Block>
				<Block name="category" label="Kategorie">
					<BlockTypeTitle />
					<InputField field="title" label="Title" required />
					<InputField field="description" label="Description" />
					<SelectField field="category" label="Kategorie">
						<Field field="name" />
					</SelectField>
					<div className="text-sm my-2 w-fit text-gray-600 rounded bg-violet-100 p-4 flex gap-2 items-center">
						<InfoIcon size="16" />
						Zobrazí se tři nejnovější články z dané kategorie
					</div>
				</Block>
				<Block name="tags" label="Tag">
					<BlockTypeTitle />
					<SelectField field="tags" label="Tag">
						<Field field="name" />
					</SelectField>
				</Block>
				<Block name="news" label="News">
					<BlockTypeTitle />
					<InputField field="title" label="Title" required />
					<div className="text-sm my-2 w-fit text-gray-600 rounded bg-violet-100 p-4 flex gap-2 items-center">
						<InfoIcon size="16" />
						Automaticky zobrazí 6 nejnovějších postů.
					</div>
				</Block>
				<Block name="form" label="Form">
					<BlockTypeTitle />
					<FormForm />
				</Block>
				<Block name="printTitles" label="Print titles">
					<InputField field="title" label="Title" />
					<div className="text-sm my-2 w-fit text-gray-600 rounded bg-violet-100 p-4 flex gap-2 items-center">
						<InfoIcon size="16" />
						Automaticky se zobrazí všechny nejnovější obálky posledních vydání titulů.
					</div>
				</Block>
			</BlockRepeater>
		</If>
		<SeoForm field="seo" />
	</FormLayout>
))
