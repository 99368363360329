import {
	DataGrid,
	DataGridColumn,
	DataGridLoader,
	DataGridPagination,
	DataGridTable,
	DataGridTextColumn,
	DataGridTiles,
	DataGridToolbar,
} from '@app/lib/datagrid'
import { ImageField } from '@app/lib/form'
import { Button } from '@app/lib/ui/button'
import { Dialog, DialogContent, DialogTrigger } from '@app/lib/ui/dialog'
import { UploadedImageView } from '@app/lib/upload'
import { EntityAccessor, Field, StaticRender, useEntity } from '@contember/interface'
import {
	FileUrlDataExtractorProps,
	GenericFileMetadataExtractorProps,
	ImageFileDataExtractorProps,
} from '@contember/react-uploader'
import { FunctionComponent, useState } from 'react'
import { UseEntity } from './use-entity'

export const imageFields: FileUrlDataExtractorProps & GenericFileMetadataExtractorProps & ImageFileDataExtractorProps = {
	urlField: 'url',
	widthField: 'width',
	heightField: 'height',
	fileNameField: 'meta.fileName',
	fileSizeField: 'meta.fileSize',
	fileTypeField: 'meta.fileType',
	lastModifiedField: 'meta.lastModified',
}

type SelectImageProps = {
	excludePhotoBank?: boolean
}

export const SelectImage: FunctionComponent<SelectImageProps> = ({ excludePhotoBank }) => {
	const entity = useEntity()

	return (
		<SelectImageInner
			connect={it => {
				entity.connectEntityAtField('image', it)
			}}
			closeOnSelect
			excludePhotoBank={excludePhotoBank}
		/>
	)
}

export const SelectImageRepeater = () => {
	const entity = useEntity()

	return (
		<SelectImageInner
			connect={it => {
				entity.getEntityList('imageList.items').createNewEntity(entity => {
					entity().connectEntityAtField('image', it)
				})
			}}
		/>
	)
}

const SelectImageInner = ({ connect, closeOnSelect, excludePhotoBank }: { connect: (entity: EntityAccessor) => void; closeOnSelect?: boolean; excludePhotoBank?: boolean }) => {
	const [open, setOpen] = useState(false)
	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button size="sm" variant="outline" onClick={e => e.stopPropagation()}>
					Select image
				</Button>
			</DialogTrigger>
			<DialogContent className="max-w-[90vw] max-h-[90vh] overflow-auto">
				<DataGrid
					entities={excludePhotoBank
						? "Image[isFromPhotoBank=false]"
						: "Image"}
				>
					<DataGridToolbar />
					<DataGridLoader>
						<DataGridTiles className="md:grid-cols-[repeat(auto-fill,minmax(10rem,1fr))]">
							<UseEntity
								render={it => (
									<div
										className="relative border rounded shadow hover:shadow-md hover:border-yellow-500"
										onClick={() => {
											it && connect(it)
											closeOnSelect && setOpen(false)
										}}
									>
										<UploadedImageView {...imageFields} />
									</div>
								)}
							/>
						</DataGridTiles>
						<DataGridTable>
							<DataGridColumn headerClassName="w-0">
								<UseEntity
									render={it => (
										<Button
											onClick={() => {
												it && connect(it)
												closeOnSelect && setOpen(false)
											}}
										>
											Select
										</Button>
									)}
								/>
							</DataGridColumn>
							<DataGridTextColumn field="url" header="URL">
								<Field field="url" />
								<StaticRender>
									<ImageField {...imageFields} />
								</StaticRender>
							</DataGridTextColumn>
						</DataGridTable>
					</DataGridLoader>
					<DataGridPagination />
				</DataGrid>
			</DialogContent>
		</Dialog>
	)
}
