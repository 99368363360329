import { Binding } from '@app/lib/binding'
import { DefaultBoard } from '@app/lib/board'
import { BackButton } from '@app/lib/buttons'
import { formatDateTime } from '@app/lib/formatting'
import { Slots } from '@app/lib/layout'
import { Button } from '@app/lib/ui/button'
import { Field, FieldView, If, Link } from '@contember/interface'

export default () => {
	const before48Hours = new Date()
	before48Hours.setHours(before48Hours.getHours() - 48)

	return (
		<>
			<Binding>
				<div className="flex flex-col gap-12">
					<Slots.Title>Statusy publikací</Slots.Title>
					<Slots.Back>
						<BackButton />
					</Slots.Back>
					<>
						<Slots.Actions>
							<Link to="articleCreate">
								<Button>Create article</Button>
							</Link>
						</Slots.Actions>
						<DefaultBoard
							entities={{
								entityName: 'Article',
								filter: { or: [{ publishedAt: { isNull: true } }, { publishedAt: { gte: before48Hours.toISOString() } }] },
							}}
							columns="Status"
							discriminationField="status"
							sortScope="board"
							orderBy={[{ publishedAt: 'desc' }]}
							limit={30}
							columnHeader={
								<div className="text-lg font-semibold">
									<Field field="name" />
								</div>
							}
							nullColumnHeader={<div className="text-lg font-semibold italic">Bez statusu</div>}
							children={
								<>
									<div className="flex gap-2 items-center mb-2">
										<If condition={`[podcast.id = ${null}]`}>
											<div className="text-xs font-medium bg-blue-100 text-blue-800 px-2 py-0.5 rounded">Article</div>
										</If>
										<If condition={`[podcast.id != ${null}]`}>
											<div className="text-xs font-medium bg-purple-100 text-purple-800 px-2 py-0.5 rounded">Podcast</div>
										</If>

										<FieldView<string>
											field="publishedAt"
											render={({ value }) => value && <div className="text-xs text-gray-500">Publikováno: {formatDateTime(value)}</div>}
										/>

										<If condition="[actualizedAt != null]">
											<div className="text-xs text-gray-500 border-l pl-2">
												Aktualizováno: <Field field="actualizedAt" format={formatDateTime} />
											</div>
										</If>
									</div>
									<Link to="articleEdit(id: $entity.id)">
										<a className="underline underline-offset-2">
											<Field field={'title'} />
										</a>
									</Link>
								</>
							}
						/>
					</>
				</div>
			</Binding>
		</>
	)
}
