import { FormContainer, FormContainerProps } from '@app/lib/form'
import { Input, InputBare, InputLike } from '@app/lib/ui/input'
import { cn } from '@app/lib/utils'
import { Component } from '@contember/interface'
import { FormFieldScope, FormInputProps } from '@contember/react-form'
import { ClipboardIcon, ExternalLinkIcon } from 'lucide-react'
import * as React from 'react'
import { ComponentProps, forwardRef, ReactNode } from 'react'
import defaultSlugify from 'slugify'
import { FormSlugInput, SlugInputOwnProps } from './FormSlugInput'

export type SlugFieldProps = Omit<FormInputProps, 'children'> &
	Omit<FormContainerProps, 'children'> &
	Omit<SlugInputOwnProps, 'slugify'> & {
		required?: boolean
		inputProps?: ComponentProps<typeof Input>
		slugify?: (value: string) => string
	}

const defaultSlugRegex = /[*+~.()`'"!:@?#%&=^|\\\/[\]{}<>,;]/g

export const SlugField = Component(({ field, label, description, inputProps, required, slugify, ...props }: SlugFieldProps) => {
	return (
		<FormFieldScope field={field}>
			<FormContainer description={description} label={label}>
				<FormSlugInput field={field} slugify={value => slugify?.(value) ?? defaultSlugify(value, { remove: defaultSlugRegex })} {...props}>
					<SlugInput required={required} {...(inputProps ?? {})} className={cn(inputProps?.className)} />
				</FormSlugInput>
			</FormContainer>
		</FormFieldScope>
	)
})

export type SlugInputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> & {
	prefix?: ReactNode
	href?: string
}

const WEBSITE_PUBLIC_URL = 'https://www.tyden.cz'

export const SlugInput = forwardRef<HTMLInputElement, SlugInputProps>(({ prefix, href, className, ...props }, ref) => {
	const targetUrl = `${WEBSITE_PUBLIC_URL}${href}`

	const copyToClipboard = () => {
		if (targetUrl) {
			navigator.clipboard.writeText(targetUrl)
		}
	}

	return (
		<InputLike className="relative w-full max-w-full">
			{prefix && <span className="-my-2 -ml-2 text-gray-400 self-stretch py-1 pl-2 flex items-center">{prefix}</span>}
			<InputBare className={cn('pr-1 w-full max-w-full', className)} {...props} ref={ref} />

			{targetUrl && (
				<div className="ml-auto self-stretch flex items-center bg-gray-50 -my-2 -mr-2 ">
					<a
						className="px-2 text-gray-600 rounded-r-md border-l hover:bg-gray-100 h-full flex items-center"
						href={targetUrl}
						target="_blank"
						rel="noreferrer"
					>
						<ExternalLinkIcon className="h-4 w-4" />
					</a>
					<button
						type="button"
						className="px-2 text-gray-600 bg-gray-50 rounded-r-md border-l hover:bg-gray-100 h-full flex items-center"
						onClick={copyToClipboard}
					>
						<ClipboardIcon className="h-4 w-4" />
					</button>
				</div>
			)}
		</InputLike>
	)
})
