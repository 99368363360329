import { PodcastForm } from '@app/components/forms/podcast-form'
import { PreviewButton } from '@app/components/previewButton'
import { Binding, DeleteEntityDialog, PersistButton } from '@app/lib/binding'
import { BackButton } from '@app/lib/buttons'
import {
	DataGrid,
	DataGridColumn,
	DataGridDateTimeColumn,
	DataGridHasOneColumn,
	DataGridLoader,
	DataGridPagination,
	DataGridQueryFilter,
	DataGridTable,
	DataGridTextColumn,
	DataGridToolbar,
} from '@app/lib/datagrid'
import { Slots } from '@app/lib/layout'
import { Button } from '@app/lib/ui/button'
import { EntitySubTree, Field, Link } from '@contember/interface'
import { TrashIcon } from 'lucide-react'

export default () => {
	return (
		<>
			<Binding>
				<div className="flex flex-col gap-12">
					<Slots.Title>Podcast edit</Slots.Title>
					<Slots.Back>
						<BackButton />
					</Slots.Back>

					<EntitySubTree entity="Podcast(id=$id)">
						<Slots.Actions>
							<PersistButton />
						</Slots.Actions>

						<PodcastForm />
					</EntitySubTree>

					<div className="flex flex-col gap-4">
						<div className="flex justify-between">
							<div className="text-lg font-bold">Posts</div>
							<Link to="podcastPostCreate">
								<Button>Create podcast post</Button>
							</Link>
						</div>
						<DataGrid entities="Article[podcast.id = $id]">
							<DataGridToolbar>
								<DataGridQueryFilter />
							</DataGridToolbar>
							<DataGridLoader>
								<DataGridTable>
									<DataGridColumn>
										<div className="flex gap-4">
											<Link to="podcastPostEdit(id: $entity.id)">
												<Button variant="secondary">Edit</Button>
											</Link>
											<PreviewButton icon />
										</div>
									</DataGridColumn>
									<DataGridTextColumn field="title" header="Title" format={value => <strong>{value}</strong>} />
									<DataGridHasOneColumn field="status" header="Status">
										<Field field="name" />
									</DataGridHasOneColumn>
									<DataGridDateTimeColumn field="publishedAt" header="Published at" />
									<DataGridColumn>
										<div className="flex justify-end items-center gap-4">
											<DeleteEntityDialog
												trigger={
													<Button variant="destructive" size="sm">
														<TrashIcon className="w-3 h-3" />
													</Button>
												}
											/>
										</div>
									</DataGridColumn>
								</DataGridTable>
							</DataGridLoader>
							<DataGridPagination />
						</DataGrid>
					</div>
				</div>
			</Binding>
		</>
	)
}
