import { PodcastPostForm } from '@app/components/forms/podcast-post-form'
import { PodcastPostPreview } from '@app/components/previews/podcast-post-preview'
import { FormLayout, SelectField } from '@app/lib/form'
import { Component } from '@contember/interface'

export interface VideoFormProps { over?: 'video' | (string & {}) }

export const VideoForm = Component(({ over } : VideoFormProps) => <FormLayout>
	{over !== 'video' && <SelectField field="podcastPost" label="Podcast post" createNewForm={<PodcastPostForm over="podcastPost" />}>
	<PodcastPostPreview />
</SelectField>
}
</FormLayout>)
