import { IssueForm } from '@app/components/forms/issue-form'
import { CheckboxField, FormLayout, InputField, TextareaField } from '@app/lib/form'
import { DefaultRepeater, RepeaterItemActions, RepeaterRemoveItemButton } from '@app/lib/repeater'
import { Component } from '@contember/interface'
import { SelectLinkField } from './select-link-field'

export interface PrintTitleFormProps {
	over?: 'printTitle' | 'printTitleDescription' | 'printTitleLogo' | (string & {})
}

export const PrintTitleForm = Component(({ over }: PrintTitleFormProps) => (
	<FormLayout>
		<InputField field="title" label="Title" required />
		<InputField field="number" label="Number" required />
		<TextareaField field="description" label="Popis" />
		<SelectLinkField field="link" label="Odkaz" />
		<CheckboxField field="isPublished" label="Publikované (ukáže obálku na webu)" />
		<InputField field="establishedYear" label="Established year" />
		{/* {over !== 'printTitle' && (
			<DefaultRepeater field="issues" title="Issues" orderBy="createdAt">
				<RepeaterItemActions>
					<RepeaterRemoveItemButton />
				</RepeaterItemActions>
				<IssueForm over="issues" />
			</DefaultRepeater>
		)} */}
	</FormLayout>
))
