import { Component } from '@contember/interface'
import { ImageRepeaterField as DefaultImageRepeaterField, ImageRepeaterFieldProps } from '@app/lib/form'

export const ImageRepeaterField = Component<Partial<ImageRepeaterFieldProps> & { field: string  }>(
	({ label, field }) => {
		return (
			<DefaultImageRepeaterField
				label={label}
				field={field}
				baseField="image"
				urlField="url"
				widthField="width"
				heightField="height"
				fileNameField="meta.fileName"
				fileTypeField="meta.fileType"
				fileSizeField="meta.fileSize"
				lastModifiedField="meta.lastModified"
				sortableBy="order"
			/>
		)
	},
)
