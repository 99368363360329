import { Component, HasOne } from '@contember/interface'
import { ImageField, InputField, TextareaField } from '@app/lib/form'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@app/lib/ui/collapsible'
import { ChevronsUpDownIcon, InfoIcon } from 'lucide-react'

interface SeoForm {
	field: string
}

export const SeoForm = Component<SeoForm>(({ field }) => (
	<Collapsible className="mt-10 border border-gray-200 p-4 rounded ">
		<CollapsibleTrigger>
			<div className="flex items-center gap-1 mt-0 font-semibold">
				SEO setting
				<ChevronsUpDownIcon className="w-4" />
				<small className="text-gray-600 font-thin ml-4 text-[12px] flex gap-2 items-center text-left">
					<InfoIcon size="12" className='flex-none' />
					If not filled, it is automatically generated from the page title and description
				</small>
			</div>
		</CollapsibleTrigger>
		<CollapsibleContent className="border border-gray-100 mt-4 p-4 rounded">
			<HasOne field={field}>
				<InputField field="title" label="Title" />
				<TextareaField field="description" label="Description" />
				<InputField field="keywords" label="Keywords" />
				<ImageField
					baseField="image"
					urlField="url"
					heightField="height"
					widthField="width"
					fileNameField="meta.fileName"
					fileSizeField="meta.fileSize"
					fileTypeField="meta.fileType"
				/>
			</HasOne>
		</CollapsibleContent>
	</Collapsible>
))
