import { FormPreview } from '@app/components/previews/form-preview'
import { Binding, DeleteEntityDialog } from '@app/lib/binding'
import {
	DataGrid,
	DataGridActionColumn,
	DataGridAutoExport,
	DataGridColumn,
	DataGridDateTimeColumn,
	DataGridHasOneColumn,
	DataGridHasOneFilter,
	DataGridLoader,
	DataGridPagination,
	DataGridQueryFilter,
	DataGridTable,
	DataGridTextColumn,
	DataGridToolbar,
} from '@app/lib/datagrid'
import { Button } from '@app/lib/ui/button'
import { FieldView } from '@contember/interface'
import { TrashIcon } from 'lucide-react'

export const list = () => {
	return (
		<Binding>
			<DataGrid entities="FormSubmission">
				<DataGridToolbar>
					<DataGridQueryFilter />
					<DataGridHasOneFilter field="form" label="Form">
						<FormPreview />
					</DataGridHasOneFilter>
				</DataGridToolbar>

				<DataGridLoader>
					<DataGridTable>
						<DataGridTextColumn field="email" header="Email" />
						<DataGridHasOneColumn field="form" header="Form">
							<FormPreview />
						</DataGridHasOneColumn>
						<DataGridDateTimeColumn field="createdAt" header="Created At" />
						<DataGridColumn header="Data">
							<FieldView
								field="additionalData"
								render={({ value }) =>
									value && (
										<div className="p-4 bg-gray-50 rounded-lg">
											{Object.entries(value).map(([key, val]) => (
												<div key={key} className="mb-3 last:mb-0">
													<span className="font-medium">{key}: </span>
													{Array.isArray(val) ? (
														<ul className="list-disc pl-5">
															{val.map((v, i) => (
																<li key={i}>{v}</li>
															))}
														</ul>
													) : (
														<span>{val}</span>
													)}
												</div>
											))}
										</div>
									)
								}
							/>
						</DataGridColumn>
						<DataGridColumn>
							<div className="flex justify-end items-center gap-4">
								<DeleteEntityDialog
									trigger={
										<Button variant="destructive" size="sm">
											<TrashIcon className="w-3 h-3" />
										</Button>
									}
								/>
							</div>
						</DataGridColumn>
					</DataGridTable>
				</DataGridLoader>
				<DataGridPagination />
			</DataGrid>
		</Binding>
	)
}
