import { IssueForm } from '@app/components/forms/issue-form'
import { Binding, PersistButton } from '@app/lib/binding'
import { BackButton } from '@app/lib/buttons'
import { dict } from '@app/lib/dict'
import { Slots } from '@app/lib/layout'
import { Button } from '@app/lib/ui/button'
import { EntitySubTree } from '@contember/interface'
import { ArrowLeftIcon } from 'lucide-react'

export default () => {
	return (
		<>
			<Binding>
				<div className="flex flex-col gap-12">
					<Slots.Title>Issue edit</Slots.Title>
					<Slots.Back>
						<Button
							variant={'ghost'}
							className={'gap-1'}
							onClick={() => {
								history.back()
								history.back()
							}}
						>
							<ArrowLeftIcon size={16} />
							<span>{dict.backButton.back}</span>
						</Button>
					</Slots.Back>
					<EntitySubTree entity="Issue(id=$id)" isCreating={false}>
						<Slots.Actions>
							<PersistButton />
						</Slots.Actions>
						<IssueForm />
					</EntitySubTree>
				</div>
			</Binding>
		</>
	)
}
