import { InputField, SelectEnumField, SelectField } from '@app/lib/form'
import { Component, Field, HasOne, If } from '@contember/interface'

export const SelectLinkField = Component<{ field: string; label?: string }>(({ field, label }) => (
	<fieldset className="grid gap-6 rounded-[30px] border p-4 bg-neutral-50 max-w-md">
		{label && <legend className="-ml-1 px-1 text-sm font-medium">{label}</legend>}
		<HasOne field={field}>
			<div className="flex flex-row gap-4">
				<SelectEnumField label="Type" field="type" options={{ external: 'External', internal: 'Internal' }} />
				<If condition="[type = external]">
					<InputField field="externalTarget" label="URL" required />
				</If>
				<If condition="[type = internal]">
					<SelectField field="internalTarget" label="Target" initialSorting={{ url: 'asc' }}>
						<Field field="url" />
					</SelectField>
				</If>
			</div>
		</HasOne>
	</fieldset>
))
